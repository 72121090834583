import axios from 'axios'
import queryString from 'query-string'
import { authority, download, resolveApiHost } from '@/utils'

/**
 * 获取补贴明细
 * @param params
 * @returns
 */
export async function getSubsidyDetails(params: { queryDate?: Array<Date | undefined> }) {
  const [beginTime, endTime] = params.queryDate || []
  if (!beginTime || !endTime) return []
  return axios.get('/avi-flight/subsidyDetails/detail/list', {
    params: {
      beginTime, endTime,
    },
  }).then(({ data }) => data)
}

/**
 * 获取补贴核对
 * @param params
 * @returns
 */
export async function getSubsidyCheck(params: { queryDate?: Array<Date | undefined> }) {
  const [beginTime, endTime] = params.queryDate || []
  if (!beginTime || !endTime) return []
  return axios.get('/avi-flight/subsidyDetails/subsidies/list', {
    params: {
      beginTime, endTime,
    },
  }).then(({ data }) => data)
}

/**
 * 获取项目汇总
 * @param params
 * @returns
 */
export async function getSubsidySummary(params: { queryDate?: Array<Date | undefined> }) {
  const [beginTime, endTime] = params.queryDate || []
  if (!beginTime || !endTime) return []
  return axios.get('/avi-flight/subsidyDetails/project/collect', {
    params: {
      beginTime, endTime,
    },
  }).then(({ data }) => data)
}

/**
 * 更新补贴
 * @param data
 * @returns
 */
export async function updateSubsidy(data: Array<any>) {
  const arr = data.map((p) => {
    const {
      id, latestDriverAllowance, firstOfficerSubsidyModified, mainPilotSubsidyModified,
    } = p
    return {
      id,
      firstOfficerSubsidyModified: firstOfficerSubsidyModified || 0,
      latestDriverAllowance: latestDriverAllowance || 0,
      mainPilotSubsidyModified: mainPilotSubsidyModified || 0,
    }
  })
  return axios.put('/avi-flight/subsidyDetails/batchupdate', arr)
}

/**
 * 导出补贴表
 * @param params
 * @returns
 */
export async function exportSubsidy(queryDate: Array<Date>) {
  const [beginTime, endTime] = queryDate
  const { token } = await authority()
  const url = queryString.stringifyUrl({
    url: resolveApiHost('/avi-flight/subsidyDetails/exportSubsidyDetail'),
    query: {
      beginTime: beginTime.toString(),
      endTime: endTime.toString(),
      access_token: token,
    },
  })

  download(url)
}
