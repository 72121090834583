<template>
<div class="PreviewPicture" v-if="isShow">
  <div class="arrows-left" @click="prev" :class="fileIndex === 0?'disabled':''">
    <!-- <i class="el-icon-arrow-left"></i> -->
    <el-icon>
      <arrow-left />
    </el-icon>
  </div>
  <div class="container">
    <div>
      <img :style="styleOptions" :src="fileList[fileIndex].url" alt="img" v-if="fileList[fileIndex].format === 'image'">
      <div v-html="idVideoShowUrl" v-if="fileList[fileIndex].format === 'video'"></div>
    </div>
  </div>
  <div class="arrows-right" @click="next" :class="fileIndex === fileList.length - 1?'disabled':''">
    <!-- <i class="el-icon-arrow-right"></i> -->
    <el-icon>
      <arrow-right />
    </el-icon>
  </div>
  <div class="close" @click="close">
    <!-- <i class="el-icon-close"></i> -->
    <el-icon>
      <close />
    </el-icon>
  </div>
  <div class="tools" v-if="fileList[fileIndex].format === 'image'">
    <el-icon @click="zoomOut"><zoom-in/></el-icon>
    <el-icon @click="zoomIn"><zoom-out/></el-icon>
    <el-icon @click="refresh"><refresh/></el-icon>
    <el-icon @click="turnLeft"><refresh-left/></el-icon>
    <el-icon @click="turnRight"><refresh-right/></el-icon>
  </div>
  <div class="count">{{fileIndex + 1}}/{{fileList.length}}</div>
</div>
</template>

<script>
export default {
  name: 'PreviewPicture',
  props: {},
  components: {},
  data() {
    return {
      isShow: false,
      scale: 1,
      rotate: 0,
      styleOptions: {
        transform: 'scale(1) rotate(0deg)',
      },
      fileList: [],
      fileIndex: 0,
      idVideoShowUrl: '',
    }
  },
  computed: {
    name() {
      return this.data
    },
  },
  watch: {
    idVideoShowUrl(n) { // 监听变化idVideoShowUrl动态插入标签
      return n
    },
  },
  methods: {
    open(fileList, fileIndex) {
      this.fileIndex = fileIndex
      this.fileList = fileList
      this.isShow = true
      if (this.fileList[this.fileIndex].format === 'video') {
        this.idVideoShowUrl = this.appendVideo(this.fileList[this.fileIndex].url)
      }
      if (this.fileList[this.fileIndex].format === 'video') {
        this.idVideoShowUrl = this.appendVideo(this.fileList[this.fileIndex].url)
      }
    },
    close() {
      this.refresh()
      this.fileList = []
      this.fileIndex = 0
      this.isShow = false
    },
    zoomOut() {
      this.scale += 0.1
      this.styleOptions.transform = `scale(${this.scale}) rotate(${this.rotate}deg)`
    },
    zoomIn() {
      this.scale -= 0.1
      this.styleOptions.transform = `scale(${this.scale}) rotate(${this.rotate}deg)`
    },
    refresh() {
      this.scale = 1
      this.rotate = 0
      this.styleOptions.transform = `scale(${this.scale}) rotate(${this.rotate}deg)`
    },
    turnLeft() {
      this.rotate -= 90
      this.styleOptions.transform = `scale(${this.scale}) rotate(${this.rotate}deg)`
    },
    turnRight() {
      this.rotate += 90
      this.styleOptions.transform = `scale(${this.scale}) rotate(${this.rotate}deg)`
    },
    prev() {
      if (this.fileIndex === 0) {
        return
      }
      this.fileIndex -= 1
      if (this.fileList[this.fileIndex].format === 'video') {
        this.idVideoShowUrl = this.appendVideo(this.fileList[this.fileIndex].url)
      }
    },
    next() {
      if (this.fileIndex === this.fileList.length - 1) {
        return
      }
      this.fileIndex += 1
      if (this.fileList[this.fileIndex].format === 'video') {
        this.idVideoShowUrl = this.appendVideo(this.fileList[this.fileIndex].url)
      }
    },
    // 返回要插入的标签 方法
    appendVideo(url) {
      return `<video width="100%" height="100%"  controls autoplay><source src=${url} type="video/mp4"></video>`
    },
  },
  filters: {},
}
</script>

<style lang="scss">
.PreviewPicture{
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
  position: absolute;
  z-index: 10000;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  .container{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      transition: all 0.3s;
      width: 80%;
    }
    video{
      transform: scale(0.8);
    }
  }
  .arrows-left, .arrows-right, .close{
    position: absolute;
    left: 20px;
    top: 50%;
    margin-top: -25px;
    width: 50px;
    height: 50px;
    background-color: rgb(48, 49, 51);
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    >i{
      font-size: 30px;
      opacity: 0.8;
    }
    &.disabled{
      opacity: 0.2;
      cursor: not-allowed;
    }
  }
  .arrows-right{
    left: unset;
    right: 20px;
  }
  .close{
    left: unset;
    right: 20px;
    top: 80px;
  }
  .tools{
    width: 240px;
    height: 40px;
    border-radius: 20px;
    background-color: #303133;
    position: absolute;
    bottom: 40px;
    z-index: 100;
    left: 50%;
    margin-left: -120px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    >i{
      font-size: 26px;
      cursor: pointer;
      opacity: 0.8;
    }
  }
  .count{
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 100;
    color: white;
    font-size: 26px;
  }
}
</style>
