import _ from 'lodash'
import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { PERSISTEDSTATE } from '@/configs/define'
import modules from './modules'

const persistedState = createPersistedState({
  key: PERSISTEDSTATE,
  paths: _.keys(modules),
  storage: window.localStorage,
})

// 此文件中定义的状态 state getters mutations actions 均为全局状态
export default createStore({
  state: {
    collapse: false, // 菜单收缩
  },
  getters: {
    isCollapse: (state) => state.collapse, // 获取菜单是否收缩展示
  },
  actions: {
    SETCOLLAPSE: ({ commit }, value) => commit('SETCOLLAPSE', value), // 设置菜单收缩展示
  },
  mutations: {
    SETCOLLAPSE(state, value) {
      state.collapse = value
    },
  },
  modules,
  plugins: [persistedState],
})
