import {
  DirectiveBinding, DirectiveHook, ObjectDirective, VNode,
} from 'vue'

import Sortable from 'sortablejs'

interface IExtraSortable {
  sortable: Sortable
}

interface SortableOptions extends Sortable.Options {
  /**
   * el-table 表格拖拽容器选择器
   * @default '.el-table__body-wrapper .el-table__body tbody'
   */
  elWrapperClass?: string
}
export class ElTableDragDirective implements ObjectDirective {
  created?: DirectiveHook<HTMLElement, null, any> | undefined = (el: HTMLElement, binding: DirectiveBinding<SortableOptions|undefined> & Partial<IExtraSortable>, vnode: VNode) => {
    const { arg = true } = binding
    if (binding.arg) {
      const tbody = el.querySelector(binding.value?.elWrapperClass || '.el-table__body-wrapper .el-table__body tbody')
      binding.sortable = Sortable.create(tbody as HTMLElement, {
        sort: false,
        ...binding.value,
      })
    }
  }

  unmounted?: DirectiveHook<any, null, any> | undefined = (el: HTMLElement, binding: DirectiveBinding & Partial<IExtraSortable>, vnode: VNode) => {
    binding.sortable?.destroy()
  }
}

export default new ElTableDragDirective()
