import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import plugin from './plugin'
import components from './components'
import directive from './directive'

import 'animate.css'
import '@/assets/style/index.scss'

// 引入全局样式
createApp(App).use(store).use(router).use(plugin)
  .use(components)
  .use(directive)
  .mount('#app')
