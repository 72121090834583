import _ from 'lodash'
import NProgress from 'nprogress'
import { authority, authorityPermissions } from '@/utils'
import {
  IRoute, IMenu, AUTH_LOGIN, MenuPermission,
} from '@/configs/define'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { menu as api } from '@/api'
import globalRoutes from './routes'

// 获取登录
// 拉取用户菜单

// 动态路由生成器
const generator = ({
  title, path, target, breadcrumb = true, icon, auth, description, redirect, children,
}: IMenu, parent: string, root = ''): IRoute => {
  const children2: Array<IRoute> = []
  if (children && children.length > 0) {
    _.orderBy(children, 'order').forEach((item) => children2.push(generator(item, `${parent}/${item.path}`)))
    // eslint-disable-next-line no-param-reassign
    if (!redirect) redirect = `/${parent}/${children2[0].path}`
  }
  const component = () => import(`@/views/${parent}.vue`).catch(() => import('./used/empty.vue'))
  return {
    name: _.uniqueId(auth || 'route_'),
    path: `${root}${path}`,
    redirect,
    children: children2,
    component,
    meta: {
      title, target, icon, breadcrumb, description, auth,
    },
  }
}
const fixedRoutes: Array<IRoute> = [
  {
    path: '/401', name: '401', component: () => import('./used/401.vue'), meta: { title: '请重新登录' },
  },
  {
    path: '/403', name: '403', component: () => import('./used/403.vue'), meta: { title: '权限不足' },
  },
  {
    path: '/500', name: '500', component: () => import('./used/500.vue'), meta: { title: '500' },
  },
  {
    path: '/:pathMatch(.*)*', name: 'NotFound', component: () => import('./used/404.vue'), meta: { title: '页面找不到了' },
  },
]
const dynamicRoutes = _.orderBy(api.all(), 'order').map((route) => generator(route, route.path, '/'))
// 加载所有路由
const routes: Array<IRoute> = [
  ...fixedRoutes,
  ...globalRoutes,
  ...dynamicRoutes,
]

// 替换默认首页
if (dynamicRoutes.length > 0 && !_.some(routes, { path: '/' })) routes.push({ path: '/', redirect: dynamicRoutes[0].path })
const allowList = _.map([...fixedRoutes, ...globalRoutes], 'name').filter((p) => p) as Array<string> // no redirect allowList

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes as Array<RouteRecordRaw>,
})
router.beforeEach(async (to) => {
  //
  NProgress.start()

  // 如果是命名路由，且属于免登路由时，直接放行
  if (to.name && allowList.includes(to.name as string)) {
    return true
  }
  // 当前路由需要鉴权
  if (to.meta && to.meta.auth) {
    const { login } = await authority()

    if (!login) {
      // 未登录，跳转登录页面
      return {
        path: AUTH_LOGIN,
        replace: true,
      }
    }
    // 已经登录后，读取路由权限
    const { permissions } = await authorityPermissions()

    if (permissions.length === 0) {
      // 没有任何页面权限，跳转403
      return {
        path: '/403',
        replace: true,
      }
    }

    if (!_.includes(permissions, to.meta.auth)) {
      // 未在权限列表中，获取有权限的第一个页面
      const route = _.chain(router.getRoutes()).filter((p: any) => p.meta && p.meta.auth).find(({ meta = {} }) => _.includes(permissions, meta.auth)).value()

      if (route) {
        return {
          path: route.path,
          replace: true,
        }
      }

      // 没有任何页面权限，跳转403
      return {
        path: '/403',
        replace: true,
      }
    }
  }
  // 代表有权限，页面执行放行
  return true
})

router.afterEach((to) => {
  if (to.meta && typeof to.meta.title !== 'undefined') { window.document.title = `${to.meta.title ? to.meta.title : to.name}-Avigator` }
  NProgress.done() // finish progress bar
})

export default router
