import _ from 'lodash'
import axios from 'axios'

const prefix = '/avi-flight'

const restUrl = `${prefix}/restTask/task`
const restPageUrl = `${prefix}/restTask/pageQuery`
const restDetailUrl = `${prefix}/restTask/detail`
const restAllocateUrl = `${prefix}/restTask/allocateTasks`
const restExportUrl = `${prefix}/restTask/exportRestTask`
/** *************************************************调休相关接口******************************************** */

export interface RestInfoData {
  date?:Array<string>, // 日期访问
  beginTime?: string, // 开始时间
  endTime?: string, // 结束时间
  deductTimeFlag?: number|string, // 是否扣除加班时长（是1; 否0)
  flyHandlerId?: Array<string>|string, // 调休人员Id（飞手Id）
  flyHandlerName?: string, // 调休人员Name（飞手Name）
  id?: number, // 任务Id
  remark?: string, // 调休备注
  status?: number // 任务状态
}

// 更新调休
export function updateRest(data:any):Promise<boolean> {
  return axios.put(restUrl, data).then(() => true)
}
// 新增调休
export function addRest(data:any):Promise<boolean> {
  return axios.post(restUrl, data).then(() => true)
}
// 删除调休
export function delRest(id:string):Promise<boolean> {
  return axios.delete(restUrl, {
    params: {
      id,
    },
  }).then(() => true)
}
// 调休详情
export function getRest(id:string):Promise<any> {
  return axios.get(restDetailUrl, {
    params: {
      taskId: id,
    },
  })
}
// 调休分页
export function getRestPage(params?: { queryDate?:string }, pageNumber = 1, pageSize = 10):Promise<any> {
  return axios.get(restPageUrl, {
    params: {
      ...params,
      pageNumber,
      pageSize,
    },
  })
}
// 下发调休
export function allocateRest(ids:Array<number>):Promise<boolean> {
  return axios.get(restAllocateUrl, {
    params: {
      ids: ids.join(','),
    },
  }).then(() => true)
}
// 导出调休
export function exportRest(beginTime:string, endTime:string):Promise<any> {
  return axios.get(restExportUrl, {
    params: {
      beginTime,
      endTime,
    },
  })
}
