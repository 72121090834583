/**
 * 系统菜单获取接口，所有系统通用不可以删除
 */
import _ from 'lodash'
import { IMenu, MenuPermission } from '@/configs/define'
import { authorityPermissions } from '@/utils'
import axios from 'axios'

const prefix = '/avi-system'

const menusCreate = `${prefix}/menus/create`
const menusUpdate = `${prefix}/menus/update`
const menusFind = `${prefix}/menus/find`
const menusMenus = `${prefix}/menus/menus`

export interface MenuItem {
   id:number,
   cname: string, // 中文名称
   ename: string, // 英文名称
   enable: boolean, // 是否启用
   pid: number, // 父节点id
   route: string, // 路由
   sort: number, // 排序
   type: string, // 菜单类型,可用值:ROUTE,URL,ACTION
   children?: Array<MenuItem>
 }

/**
  * 获取全部菜单列表
  * @returns 菜单数组
  */
export function all(): Array<IMenu> {
  const iterate = (menus: Array<IMenu> = []): Array<IMenu> => {
    const temp: Array<IMenu> = []
    menus.forEach(({
      title, path, icon, iconColor, order = 99, auth, breadcrumb = true, target = '_self', description, redirect, children, hide, hideChildren,
    }) => {
      temp.push({
        title,
        icon,
        iconColor,
        order,
        auth,
        target,
        breadcrumb,
        description,
        redirect,
        path: path.replace(/^([/\\])+|([/\\])+$/, ''),
        children: iterate(children),
        hide,
        hideChildren,
      })
    })
    return temp
  }

  // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require
  return iterate(require('@/assets/json/menu.json'))
}
/**
  * 获取系统菜单(有权限的)
  * @returns 菜单数组
  */
export async function list(): Promise<Array<IMenu>> {
  const iterate = (menus: Array<IMenu> = [], permissions:Array<string> = []): Array<IMenu> => {
    const temp: Array<IMenu> = []
    menus.forEach(({
      title, path, icon, iconColor, order = 99, auth, breadcrumb = true, target = '_self', description, redirect, children, hide = false, hideChildren,
    }) => {
      // eslint-disable-next-line no-constant-condition
      if (auth && !permissions.includes(auth)) {
        console.log(`移除 ${auth}`)
      } else {
        temp.push({
          title,
          icon,
          iconColor,
          order,
          auth,
          target,
          breadcrumb,
          description,
          redirect,
          path: path.replace(/^([/\\])+|([/\\])+$/, ''),
          children: iterate(children, permissions),
          hide,
          hideChildren,
        })
      }
    })
    return temp
  }
  const { permissions } = await authorityPermissions()
  // 获取所有权限对象的KEY
  return iterate(all(), permissions)
}

export function addMenu(data:MenuItem):Promise<boolean> {
  return axios.post(menusCreate, data).then(() => true)
}

export function updateMenu(data:MenuItem):Promise<boolean> {
  return axios.put(menusUpdate, data).then(() => true)
}

export function getUserMenus():Promise<any> {
  return axios.get(menusMenus)
}

export function getMenusList():Promise<any> {
  return axios.get(menusFind, {
    params: {
      assemblyTree: true,
    },
  })
}
