
import { IFile } from '@/configs/define'
import { defineComponent, PropType, ref } from 'vue'

export type Options = {
  showDelete: boolean;
  showDetails: boolean;
};

export default defineComponent({
  emits: ['click-remove', 'click-details'],
  props: {
    file: {
      type: Object as PropType<IFile>,
      required: true,
    },
    options: {
      type: Object as PropType<Options | undefined>,
      default: undefined,
    },
    // 是否使用默认预览
    isPreview: {
      type: Boolean,
      default: true,
    },
    // 使用自定义图片
    imgSrc: {
      type: String,
      default: '',
    },
  },
  setup() {
    const preview = ref(false)
    return {
      preview,
    }
  },
  methods: {
    onClickRemove() {
      this.$emit('click-remove', this.file)
    },
    onClickDetails() {
      this.$emit('click-details', this.file)
    },
    // 打开预览列表
    open() {
      this.preview = true
    },
  },
})
