import _ from 'lodash'
import { App } from 'vue'
/* 主题插件 */

export interface ThemeOptions {
  theme?: string;
  predefines?: { [themeName: string]: { [name: string]: string | object } };
}
/**
 * 主题实例
 */
export class Theme {
  private _cache = new Map<string, { [name: string]: string | object }>();

  private _options?: ThemeOptions;

  private _style?: HTMLStyleElement;

  constructor(options?: ThemeOptions) {
    this._options = {
      theme: undefined,
      predefines: undefined,
      ...options,
    }
    if (this._options.predefines) {
      const { theme, predefines } = this._options
      this.predefines(predefines)
      this.use(theme || Object.keys(predefines)[0])
    }
  }

  // 将属性名解析为css3属性名
  static _css3property(property:string):string {
    return property.startsWith('--') ? property : `--${property}`
  }

  /**
   * 解析dom选择器对象，并生成对应的css3变量
   * @param selector dom选择器，示例：div、.el-menu
   * @param obj 解析对象
   * @returns
   */
  static _spread(selector: string, obj: object):string {
    const css3 = _.chain(obj).map((value, property) => `${this._css3property(property)}:${value}`).join(';').value()
    return `${selector} {${css3}}`
  }

  /**
   *
   * @param variable
   */
  use(variable: string | { [name: string]: string | object }): Promise<string> {
    const { _cache } = this

    const fn = (name: string): Promise<string> => {
      const cache = _cache.get(name) as { [name: string]: string }
      const el = document.createElement('style')
      const css: string[] = []
      const css2: string[] = []
      _.forIn(cache, (value, property) => {
        // 代表属于子类型
        if (typeof value === 'object') {
          css2.push(Theme._spread(property, value))
        } else {
          css.push(`${Theme._css3property(property)}:${value}`)
        }
      })
      el.innerHTML = `:root{${css.join(';')}} ${css2.join(' ')}`
      const oldstyle = this._style
      document.body.appendChild(el)
      if (oldstyle) {
        document.body.removeChild(oldstyle)
      }
      //   document.appendChild(el);
      this._style = el
      return Promise.resolve(name)
    }
    // 代表使用指定变量
    if (typeof variable === 'string') {
      if (!_cache.has(variable)) throw new Error(`'${variable}' does not exist`)
      return fn(variable) // 切换主题
    }
    const name = _.uniqueId('_theme_')
    this.predefine(name, variable)
    return fn(name) // 切换主题
  }

  /**
   * 预定义主题
   * @param themeName
   * @param variable
   * @returns this
   */
  predefine(themeName: string, variable: { [name: string]: string | object }): this {
    const { _cache } = this
    _cache.set(themeName, variable)
    return this
  }

  /**
   * 预定义一组主题
   * @param themes
   * @returns this
   */
  predefines(themes: { [themeName: string]: { [name: string]: string | object } }): this {
    _.forIn(themes, (variable, themeName) => this.predefine(themeName, variable))
    return this
  }
}
// import
export default {
  install(app: App) {
    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$theme = new Theme({
      // eslint-disable-next-line
      predefines: require('@/assets/style/theme/index.ts').default,
    })
  },
}
