/*
 * @Author: wangminghua
 * @Date: 2022-04-03 07:11:56
 * @Last Modified by: wangminghua
 * @Last Modified time: 2022-07-22 15:32:11
 */

/**
 * 权限KEY
 */
export const AUTHORIZATION = 'Authorization'
/**
  *数据持久化KEY
  */
export const PERSISTEDSTATE = 'AVIGATORCITYPCPERSISTEDSTATE'
/**
  * 登录URL
  */
export const AUTH_LOGIN = '/login'
/**
  * DES KEY
  */
export const CRYPTO_KEY_DES = 'txKFyo9fDQi4XU09'

/**
  * 获取进度值回调
  */
export type ProgressCallback = (
   // 选项值
   opts: {
     /**
      * 当前上传大小
      */
     current: number,
     /**
      * 总上传大小
      */
     total: number,
     /**
      * 当前百分比
      */
     percent: number
   }) => void

/**
  * 定义文件接口
  */
export interface IFile {
   id?: string | number
   /**
    * 文件地址
    */
   url: string
   /**
    * 缩略图
    */
   thumbnail?: string
   /**
    * 返回文件的名称.由于安全原因,返回的值并不包含文件路径.
    */
   name: string
   /**
    * Blob 对象中所包含数据的大小（字节）。
    */
   size: number
   /**
    * 一个字符串，表明该 Blob 对象所包含数据的 MIME 类型。如果类型未知，则该值为空字符串。
    */
   type: string
 }
 /**
  * 规定在何处打开目标 URL。仅在 href 属性存在时使用。
  * _blank：新窗口打开。
  * _parent：在父窗口中打开链接。
  * _self：默认，当前页面跳转。
  * _top：在当前窗体打开链接，并替换当前的整个窗体(框架页)。
  */
export type Target = '_blank' | '_parent' | '_self' | '_top';
/**
  * 菜单实例接口
  */
export interface IMenu {
   /**
    * 菜单标题
    */
   title: string;
   /**
    * view 目录
    */
   path: string;
   // 固定路由名称
   name?: string;
   /**
    * 字体图标
    */
   icon?: string;
   /**
    * 字体图标颜色
    */
   iconColor?: string;
   /**
    * 顺序
    */
   order?: number;
   /**
    * 身份验证标识，此字段有值时代表该菜单需要鉴权才可以加载
    */
   auth?: string
   /**
    * 进行面包屑导航，默认值 true，代表显示面包屑
    */
   breadcrumb?: boolean
   /**
    * 规定在何处打开目标 URL。默认值为：_self 当前页面跳转
    */
   target?: Target;
   /**
    * 菜单描述
    */
   description?: string;
   /**
    * 重定向
    */
   redirect?: string;
   /**
    * 子路由
    */
   children?: Array<IMenu>;
   /**
    * 是否隐藏路由菜单
    */
   hide?: boolean
   /**
    * 是否隐藏子路由菜单
    */
   hideChildren?: boolean
 }

/**
  * 定义路由对象
  */
export interface IRoute {
   /**
    * 路由路径
    */
   path: string,
   /**
    * 重定向路径
    */
   redirect?: string,
   /**
    * 命名路由名称
    */
   name?: string
   /**
    * 元信息
    */
   meta?: {
     [property: string]: any
     /**
      * 定义可移除的路由，同时 route name 是必须的，将会在拉取权限后，移除可移除路由
      */
     removable?: boolean
   }
   // 路由页面
   component?: any
   // 子路由
   children?: Array<IRoute>
 }

/**
  * 分页查询参数
  */
export interface IPage {
   /**
    * 页码
    */
   page: number

   /**
    * 页数量
    */
   pageSize: number
 }

/**
  * VUEX actions 对应提交类型
  */
export interface ICommit<ValueType = unknown> {
   commit: (mutations: string, val: ValueType) => void
 }

export type PermissionDataType = 'READ' | 'EDIT'

/**
  * 定义权限类型
  */
export type Permission = {
   /**
    * 权限名称
    */
   name: string
   /**
    * 权限KEY（必须唯一）
    */
   value: string
   /**
    * 数据权限，示例：'READ' | 'EDIT'
    */
   data?: PermissionDataType
   /**
    * 数据权限映射
    */
   dataMap?: {
     [name: string]: string
   }
   /**
    * ROUTE 内部路由，
    * URL 外部网址
    * ACTION 操作按钮
    */
   type?: 'ROUTE' | 'URL' | 'ACTION'
   /**
    * 外部URL地址，仅当 type 为 'URL' 时有效
    */
   externalURL?: string
   /**
    * 子项权限
    */
   children?: Array<Permission>
 }

// 菜单权限
export type MenuPermission = {
  menuId: string, // 菜单id
  ename?:string, // 菜单英文名称
  permission:'READ'|'EDIT', // 菜单权限
  isLeaf:boolean, // 是否叶节点
}
