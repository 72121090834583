import axios from 'axios'
import { UploadProgressEvent } from 'element-plus'
import { MenuPermission, Permission, PermissionDataType } from '@/configs/define'

let permissionRequest: Promise<any> | undefined

/**
 * 动态重置请求
 */
export function reset() {
  permissionRequest = axios.get('/avi-system/menus/menus?assemblyTree=true').then(({ data }) => {
    const fn = (o: any): Permission & { [name: string]: any } => {
      const {
        id: menuId, cname: name, ename: value, type, permission, route, children = [],
      } = o
      return {
        menuId,
        name,
        value,
        ename: value,
        type,
        externalURL: route,
        data: permission,
        permission,
        children: children.map((c: any) => fn(c)),
        isLeaf: children.length === 0,
      }
    }
    return data.map((p: any) => fn(p))
  })
}

// 获取系统权限清单
export function permissions(): Promise<Array<Permission>> {
  if (!permissionRequest) reset()
  return permissionRequest as Promise<Array<Permission>>
}

/**
 * 解码 Permission 为字符串数组
 * @param permission
 * @returns
 */
export function decoding(permission: Array<Permission>): {
  permissions: Array<string>,
  menuPermissions: Map<string, MenuPermission>
} {
  const fn = (arr: Array<Permission>, _permissions: Array<string>, _menuPermissions: Map<string, MenuPermission>) => {
    arr.forEach((p) => {
      _permissions.push(p.value)
      _menuPermissions.set(p.value, p as unknown as MenuPermission)
      fn(p.children || [], _permissions, _menuPermissions)
    })
  }
  const _permissions: Array<string> = []
  const _menuPermissions: Map<string, MenuPermission> = new Map<string, MenuPermission>()

  fn(permission, _permissions, _menuPermissions)

  return {
    permissions: _permissions,
    menuPermissions: _menuPermissions,
  }
}

export async function oss(file: File, progress: (evt: UploadProgressEvent) => void): Promise<void> {
  const { data } = await axios.post('/avi-system/oss/make/upload/messages', {
    fileName: file.name, contentType: file.type,
  })
  const {
    uploadUrl, url, id,
  } = data
  Object.assign(file, {
    id,
    url,
  })
  await axios.put(uploadUrl, file, {
    headers: {
      'Content-Type': file.type,
    },
    timeout: 200000,
    onUploadProgress: (evt: ProgressEvent) => {
      const { loaded, total } = evt
      const percent = loaded / total
      progress({
        ...evt,
        percent,
      })
    },
  })
  return uploadUrl
}
