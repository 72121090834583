import axios from 'axios'

const prefix = '/avi-system'

// 用户列表(查询)
export function userList(data:any) {
  return axios.get(`${prefix}/user/page`, { params: data })
}
// 角色下拉列表
export function roleList() {
  return axios.get(`${prefix}/role/dropList`)
}

// 用户编辑
export function userEdit(data:any) {
  return axios.put(`${prefix}/user`, data)
}

// 用户删除
export function userDelete(data:any) {
  return axios({
    url: `${prefix}/user`,
    method: 'delete',
    data,
  })
}
