<template>
  <div class="app-system-header header">
    <div class="breadcrumb">
      <el-breadcrumb separator="/">
        <template
          v-for="item in matched"
          :key="item.path"
        >
          <el-breadcrumb-item :to="{ path: item.path }">
            {{ item.meta && item.meta.title }}
          </el-breadcrumb-item>
        </template>
      </el-breadcrumb>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    /**
     * 跳过菜单层级，默认由菜单树组成的路由，可能存在不想使用的层级菜单，比如第一层（根），此时应设置值为1
     */
    skip: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    //
  },
  computed: {
    matched() {
      return this.$route.matched
        .slice(this.skip)
        .filter((p) => p.meta && p.meta.breadcrumb)
    },
  },
})
</script>

<style lang="scss">
.app-system-header {
  .el-breadcrumb__inner.is-link,
  .el-breadcrumb__inner a {
    font-weight: normal;
  }
}
</style>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  // border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  margin: $app-system-header-margin;
  .breadcrumb {
    flex: 1;
  }
}
</style>
