import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b678b0b6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "aw" }
const _hoisted_2 = { class: "aw ah image-error" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "el-upload-list__item-actions" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_image = _resolveComponent("el-image")!
  const _component_zoom_in = _resolveComponent("zoom-in")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_Delete = _resolveComponent("Delete")!
  const _component_Plus = _resolveComponent("Plus")!
  const _component_el_upload = _resolveComponent("el-upload")!
  const _component_el_image_viewer = _resolveComponent("el-image-viewer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_upload, {
      class: "app-upload-picture-card",
      ref: "upload",
      accept: _ctx.accept,
      limit: _ctx.limit,
      "list-type": "picture-card",
      "on-preview": _ctx.onPreview,
      "on-remove": _ctx.onRemove,
      "on-success": _ctx.onSuccess,
      "before-upload": _ctx.onBeforeUpload,
      "on-exceed": _ctx.onExceed,
      multiple: _ctx.multiple,
      disabled: _ctx.limit && _ctx.fileList.length >= _ctx.limit,
      "file-list": _ctx.fileList,
      "http-request": _ctx.httpRequest,
      method: "PUT"
    }, {
      file: _withCtx(({ file }) => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_el_image, {
            fit: "cover",
            class: "el-upload-list__item-thumbnail",
            src: file.url
          }, {
            error: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("img", {
                  src: require('@/assets/image/img-404-b.png'),
                  alt: "加载失败"
                }, null, 8, _hoisted_3)
              ])
            ]),
            _: 2
          }, 1032, ["src"]),
          _createElementVNode("span", _hoisted_4, [
            _createElementVNode("span", {
              class: "el-upload-list__item-preview",
              onClick: ($event: any) => {;(_ctx.onPreview || _ctx.onDefaultPreview)(file)}
            }, [
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_zoom_in)
                ]),
                _: 1
              })
            ], 8, _hoisted_5),
            (_ctx.allowEdit)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: "el-upload-list__item-delete",
                  onClick: ($event: any) => (_ctx.onRemove(file))
                }, [
                  _createVNode(_component_el_icon, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_Delete)
                    ]),
                    _: 1
                  })
                ], 8, _hoisted_6))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_icon, null, {
          default: _withCtx(() => [
            _createVNode(_component_Plus)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["accept", "limit", "on-preview", "on-remove", "on-success", "before-upload", "on-exceed", "multiple", "disabled", "file-list", "http-request"]),
    (_ctx.showViewer)
      ? (_openBlock(), _createBlock(_component_el_image_viewer, {
          key: 0,
          "url-list": _ctx.previewSrcList,
          "hide-on-click-modal": false,
          teleported: true,
          "close-on-press-escape": false,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showViewer = false))
        }, {
          default: _withCtx(() => [
            (_ctx.$slots.viewer)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _renderSlot(_ctx.$slots, "viewer", {}, undefined, true)
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 3
        }, 8, ["url-list"]))
      : _createCommentVNode("", true)
  ], 64))
}