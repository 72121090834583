import { App } from 'vue'
import _ from 'lodash'
import AppIcon from './app-icon.vue'
import AppSystemEmpty from './app-system-empty.vue'
import AppSystemMenu from './app-system-menu.vue'
import AppSystemMenuInfiniteItem from './app-system-menu-infinite-item.vue'
import AppSystemHeader from './app-system-header.vue'
import AppSystemTable from './app-system-table.vue'
import AppSystemSearchBox from './app-system-search-box.vue'
import AppSystemDialog from './app-system-dialog.vue'
import MediaSystemImage from './media/system-image.vue'
import AppPreviewPicture from './app-preview-picture.vue'
import AppUploadPictureCard from './app-upload-picture-card.vue'
import AppSystemSecondaryLayout from './app-system-secondary-layout.vue'
import AppSystemSecondaryLayout2 from './app-system-secondary-layout2.vue'
import AppAmap from './map/app-amap.vue'
import AppAmapLayerSwitcher from './map/app-amap-layer-switcher.vue'
import AppAmapZoom from './map/app-amap-zoom.vue'
import AppSystemCalendar from './app-system-calendar.vue'
import AppSystemWeather from './app-system-weather.vue'

// el 改写组件
import ElInputLimit from './el/input-limit.vue'

export {
  AppIcon,
  AppSystemEmpty,
  AppSystemMenu,
  AppSystemMenuInfiniteItem,
  AppSystemHeader,
  AppSystemTable,
  AppSystemSearchBox,
  AppSystemDialog,
  MediaSystemImage,
  AppPreviewPicture,
  AppUploadPictureCard,
  AppSystemSecondaryLayout,
  AppSystemSecondaryLayout2,
  AppAmap,
  AppAmapLayerSwitcher,
  AppAmapZoom,
  AppSystemCalendar,
  AppSystemWeather,

  ElInputLimit,
}

export default {
  install(app: App) {
    _.forIn({
      AppIcon,
      AppSystemEmpty,
      AppSystemMenu,
      AppSystemMenuInfiniteItem,
      AppSystemHeader,
      AppSystemTable,
      AppSystemSearchBox,
      AppSystemDialog,
      MediaSystemImage,
      AppPreviewPicture,
      AppUploadPictureCard,
      AppSystemSecondaryLayout,
      AppSystemSecondaryLayout2,
      AppAmap,
      AppAmapLayerSwitcher,
      AppAmapZoom,
      AppSystemCalendar,
      AppSystemWeather,

      ElInputLimit,
    }, (component, name) => {
      app.component(_.kebabCase(name), component)
    })
  },
}
