import { IRoute } from '@/configs/define'

const routes:Array<IRoute> = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue'),
    meta: {
      title: '登录',
    },
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/container.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/container/home.vue'),
      },
    ],
    // component: () => import('@/views/container/home.vue'),
    meta: {
      title: '首页',
    },
  },
]
export default routes
