import { App } from 'vue'
import dayjs from 'dayjs'

import 'dayjs/locale/zh-cn'

dayjs.locale('zh-cn') // use locale globally

export type FormatType = 'date' | 'time'

// 修改Date 原型链 toString 为 toISOString
// eslint-disable-next-line no-extend-native, func-names
Date.prototype.toString = function () {
  return this.toISOString()
}
export default {
  install(app: App) {
    app.mixin({
      methods: {
        /**
         * 日期格式化
         * @param date 时间日期
         * @param format 格式化字符串，默认值：'YYYY-MM-DD HH:mm:ss'，可选值：'date' | 'time'
         * @link https://dayjs.fenxianglu.cn/category/display.html#%E6%A0%BC%E5%BC%8F%E5%8C%96
         */
        dateformat(date: string | number | Date, format: FormatType | string = 'YYYY/MM/DD HH:mm:ss') {
          let str = ''
          switch (format) {
            case 'date': str = dayjs(date).format('YYYY/MM/DD'); break
            case 'time': str = dayjs(date).format('HH:mm:ss'); break
            default:
              str = dayjs(date).format(format); break
          }
          return str
        },
      },
    })
  },
}
