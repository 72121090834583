
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * 仅显示图标，无hover相关交互
     */
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  setup() {
    return {}
  },
  computed: {
    href() {
      return /^#/.test(this.name) ? this.name : `#${this.name}`
    },
  },
  mounted() {
    // todo
  },
  methods: {
    handleClick(evt: any) {
      if (this.disabled || this.readonly) {
        return
      }
      this.$emit('click', evt)
    },
  },
})
