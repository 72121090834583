
import _ from 'lodash'
import { menu as api, login, system } from '@/api'
import { defineComponent, ref, toRaw } from 'vue'
import { mapGetters } from 'vuex'
import { IMenu } from '@/configs/define'
import { setAuthority, authority } from '@/utils'
import {
  onBeforeRouteUpdate,
  RouteLocationMatched,
  RouteLocationNormalized,
} from 'vue-router'
import AppSystemMenuInfiniteItem from './app-system-menu-infinite-item.vue'

export default defineComponent({
  components: {
    AppSystemMenuInfiniteItem,
  },
  props: {
    load: {
      type: Function,
    },
    /**
     * 跳过菜单层级，默认由菜单树组成的路由，可能存在不想使用的层级菜单，比如第一层（根），此时应设置值为1
     */
    skip: {
      type: Number,
      default: 0,
    },
    /**
     * 默认打开所有菜单
     */
    opened: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const homeMenus = ref<Array<IMenu>>([
      {
        title: '首页',
        path: '/container/home',
        target: '_self',
        icon: 'icon-Menu-home',
        iconColor: '#ED8B75',
      },
    ])
    const menus = ref<Array<IMenu>>([])
    const active = ref('')
    const getRouteActive = (matched: RouteLocationMatched[]) => {
      const matche: (path: string, allmenus: Array<IMenu>) => boolean = (
        path,
        allmenus: Array<IMenu>,
      ) => {
        if (allmenus.length === 0) return false

        const result = _.find(allmenus, (m) => m.path === path)
        if (result) return true
        return matche(path, _.flatten(allmenus.map((p) => p.children || [])))
      }
      // 匹配
      const activeStr = _.chain(matched)
        .map((p) => p.path)
        .reverse()
        .find((path) => matche(path, menus.value))
        .value()
      console.log(activeStr)
      return activeStr
    }

    onBeforeRouteUpdate((to: RouteLocationNormalized) => {
      active.value = getRouteActive(to.matched)
    })
    return {
      active,
      show: ref(false),
      menus,
      homeMenus,
      openeds: ref<Array<string>>([]),
      getRouteActive,
    }
  },
  computed: {
    ...mapGetters(['isCollapse']),
  },
  methods: {
    parsing(m: IMenu, root: string, allShow = false): IMenu {
      const { path, children, hideChildren = false } = m
      const parent = `${root}${path}`
      this.opened && this.openeds.push(parent)
      return {
        ...m,
        children:
          !hideChildren || allShow
            ? _.chain(children)
              .orderBy('order')
              .map((m2) => this.parsing(m2, `${parent}/`, allShow))
              .filter((m2) => !m2.hide || allShow)
              .value()
            : [],
        path: parent,
      }
    },
    spread(m: Array<IMenu>, skip: number): Array<IMenu> {
      if (skip > 0) {
        return this.spread(
          _.chain(m)
            .map((p) => p.children || [])
            .flatten()
            .orderBy('order')
            .value(),
          skip - 1,
        )
      }
      return m
    },
  },
  async mounted() {
    const { matched } = this.$route
    const menus: Array<IMenu> = await (this.load || api.list)()
    this.menus = _.chain(menus)
      .filter((m) => !m.hide)
      .map((m) => this.parsing(m, '/'))
      .thru((m) => this.spread(m, this.skip))
      .value()
    this.active = await this.getRouteActive(matched)
    this.show = true
  },
})
