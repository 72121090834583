
import _ from 'lodash'
import { defineComponent } from 'vue'
import appIcon from './app-icon.vue'

export default defineComponent({
  components: { appIcon },
  props: {
    isSuffix: {
      type: Boolean,
      default: true,
    },
    btnText: {
      type: String,
      default: '新建',
    },
  },
  emits: ['search', 'reset', 'add'],
  setup(props, context) {
    const onSearch = () => context.emit('search')
    const onReset = () => context.emit('reset')
    const onAdd = () => context.emit('add')
    return {
      onSearch: _.debounce(onSearch, 300),
      onReset: _.debounce(onReset, 300),
      onAdd: _.debounce(onAdd, 300),
    }
  },
})
