import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-248e211c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "root aw ah" }
const _hoisted_2 = {
  key: 0,
  class: "mask"
}
const _hoisted_3 = { class: "c ah" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_image = _resolveComponent("el-image")!
  const _component_search = _resolveComponent("search")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_link = _resolveComponent("el-link")!
  const _component_delete = _resolveComponent("delete")!
  const _component_el_space = _resolveComponent("el-space")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_image, {
      ref: "img",
      class: "img aw ah",
      "preview-teleported": true,
      "preview-src-list": _ctx.isPreview?[`${_ctx.baseURL}/files/preview/${_ctx.file.url}`]:undefined,
      src: _ctx.imgSrc?_ctx.imgSrc:`${_ctx.baseURL}/files/thumbnails/${_ctx.file.url}`
    }, null, 8, ["preview-src-list", "src"]),
    (_ctx.options)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_el_space, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_link, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_icon, {
                      onClick: _cache[0] || (_cache[0] = ($event: any) => {
                _ctx.open();
                _ctx.onClickDetails(_ctx.file);
              })
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_search)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                (_ctx.options.showDelete)
                  ? (_openBlock(), _createBlock(_component_el_link, {
                      key: 0,
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onClickRemove(_ctx.file)))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_icon, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_delete)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.preview)
      ? (_openBlock(), _createBlock(_component_el_dialog, {
          key: 1,
          modelValue: _ctx.preview,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.preview) = $event)),
          fullscreen: true,
          "close-on-click-modal": false,
          "close-on-press-escape": false
        }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              class: "aw ah",
              src: `${_ctx.baseURL}/files/preview/${_ctx.file.id}`,
              alt: ""
            }, null, 8, _hoisted_4)
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true)
  ]))
}