import _ from 'lodash'
import axios from 'axios'
import { MenuPermission } from '@/configs/define'

const prefix = '/avi-system'

const roleCreate = `${prefix}/role/create`
const roleDelete = `${prefix}/role/delete`
const roleDetail = `${prefix}/role/detail`
const rolePage = `${prefix}/role/page`
const roleUpdate = `${prefix}/role/update`

// 角色权限
export interface RoleInfo {
  cname: string, // 中文名称
  ename: string, // 英文名称
  ownMenus: Array<MenuPermission>, // 权限菜单
}

export function getRolePage(params?: { cname?:string, ename?:string }, pageNumber = 1, pageSize = 10):Promise<any> {
  return axios.get(rolePage, {
    params: {
      ...params,
      pageNumber,
      pageSize,
    },
  })
}

export function updateRole(data:RoleInfo):Promise<boolean> {
  return axios.put(roleUpdate, data).then(() => true)
}

export function addRole(data:RoleInfo):Promise<boolean> {
  return axios.post(roleCreate, data).then(() => true)
}

export function getRole(id:string):Promise<any> {
  return axios.get(roleDetail, {
    params: {
      id,
    },
  })
}

export function delRole(id:string):Promise<boolean> {
  return axios.delete(roleDelete, {
    params: {
      ids: [id].toString(),
    },
  }).then(() => true)
}
