import axios from 'axios'

const prefix = '/avi-flight'

// 根据字典类型查询字典数据信息
export function dictType(data:any) {
  return axios.get('/avi-system/dictDatas/type', { params: data })
}
// 省市区下拉接口字典
export function shenshiqu() {
  return axios.get('/avi-system/districts/getAll')
}
// 飞手列表(查询)
export function flyhanderList(data:any) {
  return axios.post(`${prefix}/flyhander/page`, data)
}
// 同步角色是飞手的用户到飞手信息
export function synchronizeFlyHander() {
  return axios.get(`${prefix}/flyhander/synchronizeFlyHander`)
}

// 获取飞手详情
export function flyhanderDetails(id:number) {
  return axios.get(`${prefix}/flyhander/detail`, { params: { id } })
}

// 飞手修改
export function flyhanderUpdate(data:any) {
  return axios.put(`${prefix}/flyhander`, data)
}

// 飞手下拉列表
export function flyDropList() {
  return axios.get(`${prefix}/flyhander/politDropList`)
}

// 查询时间内飞手下拉列表.状态
export function flyStatesList(params?:{beginTime?: string, endTime?:string, dayScope?:boolean}) {
  return axios.get(`${prefix}/flyhander/politWithStatuList`, {
    params: {
      ...params,
    },
  })
}
