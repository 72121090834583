import _ from 'lodash'
import { App } from 'vue'
import ElTableDrag from './el-table-drag'
import InputLimit from './input-limit'

export default {
  install(app: App) {
    _.forIn({
      ElTableDrag,
      InputLimit,
    }, (directive, name) => {
      app.directive(_.kebabCase(name), directive)
    })
  },
}
