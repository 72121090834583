import { App } from 'vue'
import axios from 'axios'
import cryptoJS from 'crypto-js'
import queryString from 'query-string'

import { log, authority } from '@/utils'
import _ from 'lodash'
// import { login as api } from '@/api'
import { AUTHORIZATION } from '@/configs/define'

// // 刷新 Promise
// let refresh:Promise<any>|undefined

// /**
//  * 判断Token是否失效
//  * @param authorization jwt
//  * @returns 失效返回true
//  */
// function hasTheFailure(expires:Date):boolean {
//   return new Date() > expires
// }

// 配置baseURL
axios.defaults.baseURL = process.env.VUE_APP_BASEURL
// 配置cmsg
axios.defaults.headers.common.cmsg = '5166f259fb07c67654c712336c7aa35dfb77c329b0e3d3887a78c55f92c28e7c45142dfff1a2cd6b'
// 配置请求参数序列化
axios.defaults.paramsSerializer = (params) => queryString.stringify(params, {
  arrayFormat: 'separator',
})

// 拦截加密密码
axios.interceptors.request.use((config) => {
  const { security, data, params } = config
  if (security && (data || typeof params === 'object')) {
    const temp = data || params
    _.keys(security).forEach((property) => {
      switch (security[property]) {
        case 'aes':
        case 'des':
          console.warn('暂未实现')
          break
        case 'md5':
          temp[property] = cryptoJS.MD5(temp[property]).toString()
          break
        case 'sha-256':
        case 'sha-512':
        default:
          console.warn('暂未实现')
          break
      }
    })
  }
  return config
}, (error) => Promise.reject(error))

// 添加请求拦截器
axios.interceptors.request.use(async (config) => {
  const { clearAuthorization = false } = config
  // 清理权限不执行后续操作
  if (clearAuthorization) return config

  // eslint-disable-next-line no-param-reassign
  if (config.headers === undefined) { config.headers = {} }
  const { token, login } = await authority()

  if (!login) {
    return config
  }
  // eslint-disable-next-line no-param-reassign
  config.headers[AUTHORIZATION] = `bearer ${token}`

  return config
}, (error) => Promise.reject(error))

// 添加响应拦截器（对401和403状态进行拦截）
axios.interceptors.response.use((response) => response, (error) => {
  if (error.response) {
    if (error.response.data && error.response.data.msg) {
      // eslint-disable-next-line no-param-reassign
      error.message = error.response.data.msg
    }
    switch (error.response.status) {
      case 401:
        window.location.href = `/401?redirect=${window.location.pathname}`
        break
      case 403:
        window.location.href = `/403?redirect=${window.location.pathname}`
        break
      default:
        break
    }
  }
  return Promise.reject(error)
})

// 添加响应拦截器
axios.interceptors.response.use((response) => {
  const { data } = response
  if (data && data.success) {
    return { ...response, data: data.data }
  }
  return response
}, (error) => Promise.reject(error))

export default {
  install(app: App) {
    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$http = axios
  },
}
