// eslint-disable-next-line max-classes-per-file
import { App } from 'vue'
import _ from 'lodash'
import element, {
  MessageParamsTyped, MessageTypedFn, ElMessageBoxShortcutMethod, ElMessageBox, ElMessageBoxOptions,
} from 'element-plus'
import * as icons from '@element-plus/icons-vue'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import '@/assets/style/element.scss'
import 'dayjs/locale/zh-cn'

class SuccessMessageHandler implements ProxyHandler<MessageTypedFn> {
  apply(target: MessageTypedFn, thisArg: any, argArray: [MessageParamsTyped | undefined, any]): any {
    const message = '操作成功'
    const [options, appContext] = argArray
    if (options) {
      _.merge({ message }, options)
    }
    return target.apply(thisArg, [options || message, appContext])
  }
}

class ConfirmMessageBoxHandler implements ProxyHandler<ElMessageBoxShortcutMethod> {
  apply(target: ElMessageBoxShortcutMethod, thisArg: any, argArray: [ElMessageBoxOptions['message'], ElMessageBoxOptions['title'] | ElMessageBoxOptions, ElMessageBoxOptions | undefined]) {
    const [message, title, options] = argArray
    let mytitle = '提示'
    const myoptions: ElMessageBoxOptions = {
      type: 'warning',
      closeOnClickModal: false,
      closeOnPressEscape: false,
      confirmButtonText: '确认',
    }
    if (typeof title === 'string') { mytitle = title } else if (typeof title === 'object') { Object.assign(myoptions, title) } else { Object.assign(myoptions, options) }
    const { type, customClass = '' } = myoptions

    myoptions.customClass = `${customClass} ${type}`
    return target(message, mytitle, {
      ...myoptions,
      type,
    })
  }
}

/**
 * 功能说明：this.$message.success() // 弹出提示框 操作成功
 */
export default {
  install(app: App) {
    app.use(element, { locale: zhCn, zIndex: 3000 })
    _.forIn(icons, (icon, name) => {
      app.component(name, icon)
    })
    app.mixin({
      computed: {
        $message() {
          return new Proxy(app.config.globalProperties.$message, {
            get(target: any, property: string): MessageTypedFn {
              let temp: MessageTypedFn
              switch (property) {
                case 'success':
                  temp = new Proxy(target[property], new SuccessMessageHandler())
                  break
                default:
                  temp = target[property]
                  break
              }
              return temp
            },
          })
        },
        $confirm() {
          return new Proxy(app.config.globalProperties.$confirm, new ConfirmMessageBoxHandler())
        },
      },
      methods: {
        // eslint-disable-next-line vue/no-reserved-keys
        $delete() {
          return this.$confirm('确认是否删除数据?', '删除提示')
        },
      },
    })
  },
}
