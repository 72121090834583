
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'app-system-menu-infinite-item',
  props: {
    menu: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {}
  },
  computed: {
    isRoot() {
      return !(this.menu.children && this.menu.children.length > 0)
    },
  },
})
