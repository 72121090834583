import { App } from 'vue'
import VueAMap, { initAMapApiLoader } from '@vuemap/vue-amap'
import '@vuemap/vue-amap/dist/style.css'

export const key = process.env.VUE_APP_AMAP_KEY as string
export const securityJsCode = process.env.VUE_APP_AMAP_SECURITY_CODE as string
export const plugins = ['AMap.MoveAnimation', 'AMap.AutoComplete', 'AMap.Geocoder', 'AMap.PlaceSearch']

initAMapApiLoader({ key, plugins, securityJsCode })

export default {
  install(app: App) {
    // 注册 @vuemap/vue-amap
    app.use(VueAMap)
  },
}
