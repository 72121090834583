import cryptoJS from 'crypto-js'

// eslint-disable-next-line import/extensions
import { ICommit, CRYPTO_KEY_DES } from '@/configs/define'

interface InputValue {
  remember: boolean
  account: string
  password: string
}

export default {
  state: {
    /**
     * 是否记住密码
     */
    remember: false,
    /**
     * 用户账号
     */
    account: '',
    /**
     * 用户密码
     */
    password: '',
  },
  getters: {
    AccountAndPassword(state: any): InputValue {
      const { remember } = state
      const { account } = state
      let password = ''
      try {
        password = cryptoJS.DES.decrypt(state.password, CRYPTO_KEY_DES).toString(cryptoJS.enc.Utf8)
      } catch {
        //
      }
      return remember ? {
        remember,
        account,
        password,
      } : {
        remember,
        account: '',
        password: '',
      }
    },
  },
  actions: {
    // 设置用户账号和密码
    setAccountAndPassword: ({ commit }: ICommit, value: InputValue) => commit('modifyAccountAndPassword', value),
  },
  mutations: {
    modifyAccountAndPassword(state: any, value: InputValue) {
      state.remember = value.remember
      state.account = value.account
      if (value.password) { state.password = cryptoJS.DES.encrypt(value.password, CRYPTO_KEY_DES).toString() } else {
        state.password = ''
      }
    },
  },
}
