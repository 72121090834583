
import _ from 'lodash'
import {
  defineComponent, nextTick, PropType, ref,
} from 'vue'
import { system as api } from '@/api'
import { UploadFiles, UploadRequestOptions } from 'element-plus'
/**
 * 文件限制类型，根据限制类型的对象KEY，自动化生成正则表达式进行校验
 * @example
 * {
 *    png: true,
 *    jpg: true,
 *    mp4: true
 * }
 */
export type FileLimitType = {
  [name: string]: boolean
}

export default defineComponent({
  emits: ['update:modelValue', 'preview'],
  props: {
    /**
     * 允许编辑，显示上传框和删除按钮
     */
    allowEdit: {
      type: Boolean,
      default: true,
    },
    /**
     * 自定义文件类型
     */
    accept: {
      type: String,
      default: 'image/png,image/jpeg',
    },
    /**
     * 图片上传个数限制
     */
    limit: {
      type: Number,
    },
    /**
     * v-model 默认绑定对象
     */
    modelValue: {
      type: Array,
      default: () => [],
    },
    /**
     * 上传文件校验
     */
    limitType: {
      type: Object as PropType<FileLimitType | undefined>,
    },
    /**
     * 限制文件上传错误消息
     */
    limitError: {
      type: String,
    },
    /**
     * 限制文件上传大小，单位M
     */
    limitSize: {
      type: Number,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    onPreview: {
      type: Function as PropType<(file: any) => void>,
    },
    isDefaultPreview: {
      type: Boolean,
      default: true,
    },
    /**
     * 上传结果列表倒序
     */
    reverse: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const visible = ref(false)
    const previewImageUrl = ref('')
    const myfiles = ref<any>([])
    const fileList = ref([])
    const myAction = ref('#')
    const myHeaders = ref({
      'Content-Type': '',
    })
    const showViewer = ref(false)
    // 覆盖默认的行为
    const httpRequest = async (options: UploadRequestOptions) => {
      const {
        file, onProgress, onError, onSuccess,
      } = options
      return api.oss(file, onProgress)
    }
    return {
      showViewer,
      myAction,
      myHeaders,
      visible,
      myfiles,
      previewImageUrl,
      fileList,
      httpRequest,
    }
  },
  computed: {
    previewSrcList() {
      return _.union([
        this.previewImageUrl,
        ...this.fileList.map((p: any) => p.preview),
      ])
    },
  },
  methods: {
    async onBeforeUpload(file: any) {
      const { limitType, limitError } = this
      // 无限制对象时，不做上传限制
      if (limitType) {
        const pattern = _.chain(limitType)
          .keys()
          .filter((key) => limitType[key])
          .map((key) => `.${key}`)
          .join('|')
          .value()
        const reg = new RegExp(`(${pattern})$`, 'i')
        // 触发报错
        const errorFn = () => {
          if (limitError) {
            // 支持上传png、jpg格式、大小不超过10M、最多上传5张
            this.$message.error(limitError)
          }
        }
        if (!reg.test(file.name)) {
          errorFn()
          return false
        }
        if (this.limitSize && file.size > this.limitSize * 1024 * 1024) {
          errorFn()
          return false
        }
      }
      // const uploadAction = await api.oss(file)
      // this.myAction = uploadAction
      // this.myHeaders['Content-Type'] = file.type
      return true
    },
    onRemove(file: any) {
      _.pullAllBy(this.myfiles, [file], 'uid')
      this.cloneFileList()
    },
    onSuccess(req: any, file: any, fileList: any) {
      const temp = {
        uid: file.uid,
        // id: file.raw.id,
        name: file.name,
        type: file.raw.type,
        size: file.size,
        url: file.raw.url,
        thumbnail: file.raw.url,
      }
      if (!this.reverse) this.myfiles.splice(0, 0, temp)
      else this.myfiles.push(temp)
      if (this.myfiles.length >= fileList.length) {
        this.cloneFileList()
      }
    },
    onExceed(files: File[], uploadFiles: UploadFiles) {
      const { limitError } = this
      // 支持上传png、jpg格式、大小不超过10M、最多上传5张
      this.$message.error(limitError)
    },
    // 拷贝文件到file-list
    cloneFileList() {
      this.fileList = this.myfiles.map((f: any) => {
        const { url } = f
        return {
          uid: f.uid,
          url: url.endsWith('.mp4') ? require('@/assets/image/video.png') : url,
          preview: url,
        }
      })
    },
    async onDefaultPreview(file: any) {
      if (this.onPreview) {
        this.onPreview(file)
      } else {
        this.previewImageUrl = file.preview
        this.visible = true
        await nextTick()
        // const { preview } = this.$refs as { preview: any }
        // console.log('showViewer', preview)
        this.showViewer = true
        // preview.showViewer = true
      }
    },
  },
  async mounted() {
    this.myfiles = this.modelValue.map((file: any) => ({
      ...file,
      uid: file.url,
    }))
    // 建立双向绑定关系
    this.$emit('update:modelValue', this.myfiles)
    await nextTick()
    this.cloneFileList()
    if (!this.allowEdit) {
      const { upload } = this.$refs as { upload: any }
      upload.$el.querySelector(
        '.el-upload.el-upload--picture-card',
      ).style.display = 'none'
    }
  },
})
