import { canEditByRoute } from '@/utils'
import _ from 'lodash'
import { App } from 'vue'

export default {
  install(app: App) {
    app.mixin({
      data() {
        return {
          /**
           * 私有变量，禁止访问
           */
          // eslint-disable-next-line vue/no-reserved-keys
          __privateVariable: {
            canEdit: false,
            canEditResolved: false,
          },
        }
      },
      computed: {
        /**
         * 当前页码是否允许用户编辑
         * @returns
         */
        canEdit() {
          if (!this.__privateVariable.canEditResolved) {
            // eslint-disable-next-line vue/no-async-in-computed-properties
            canEditByRoute(this.$route).then((result) => {
              this.__privateVariable.canEdit = result
              this.__privateVariable.canEditResolved = true
            })
          }

          return this.__privateVariable.canEdit
        },
        /**
         * 从环境变量读取 API请求的基础路径地址
         * @returns
         */
        baseURL() {
          return process.env.VUE_APP_BASEURL || ''
        },
      },
    })
  },
}
