
import { ElTable } from 'element-plus'
import {
  ref,
  watch,
  nextTick,
  onMounted,
  defineComponent,
  PropType,
  computed,
  onUnmounted,
} from 'vue'
import Sortable from 'sortablejs'
import _ from 'lodash'

/**
 * 加载数据解析器
 */
export type LoadResolver<T = any> = (
  page: number,
  pageSize: number
) => Array<T> | Promise<Array<T>>

/**
 * 格式化解析器
 */
export type FormatterResolver = (val: any, col: any, row: any) => string

export interface BaseColDefine {
  /**
   * 对齐方式 left / center / right
   */
  align?: 'left' | 'center' | 'right'
  /**
   * 表头对齐方式， 若不设置该项，则使用表格的对齐方式
   */
  headerAlign?: 'left' | 'center' | 'right'
  /**
   * 列是否固定在左侧或者右侧。 true 表示固定在左侧
   */
  fixed?: true | 'left' | 'right'
  /**
   * 对应列的宽度
   */
  width?: string | number
  /**
   * 对应列的最小宽度， 对应列的最小宽度， 与 width 的区别是 width 是固定的，min-width 会把剩余宽度按比例分配给设置了 min-width 的列
   */
  minWidth?: string | number
}
/**
 * 数据列定义
 */
export interface ColDefine extends BaseColDefine {
  /**
   * 列字段
   */
  name: string
  /**
   * 中文名
   */
  zhName?: string
  /**
   * 是否省略，默认值为true
   */
  ellipsis?: boolean
  /**
   * 格式化显示值，slot 存在时无效
   */
  formatter?: FormatterResolver
  /**
   * 数据列插槽名
   */
  slot?: string
  /**
   * 空数据时，显示的字符串，默认值无
   */
  empty?: string
  /**
   * 统计列
   */
  summary?: boolean
}

/**
 * 特殊列定义，适用于 Table-column type = selection / index / expand 的情况，特殊列定义有且仅有一个
 */
export interface SpecialColDefine extends BaseColDefine {
  /**
   * 列类型 Table-column type
   * selection 多选列
   * index 索引列
   * expand 展开列
   * @link https://element-plus.gitee.io/zh-CN/component/table.html#table-column-%E5%B1%9E%E6%80%A7
   */
  type: 'selection' | 'index' | 'expand'
  // 中文名
  zhName?: string
  /**
   * 数据列插槽名
   */
  slot?: string

  /**
   * 仅对 type=selection 的列有效，类型为 Function，Function 的返回值用来决定这一行的 CheckBox 是否可以勾选
   */
  selectable?: (row: any, index: number) => boolean
}

// 操作项定义
export interface OptionsDefine {
  // 禁用的，默认值为： false 代表隐藏按钮
  disabled?: boolean
  /**
   * 显示详情
   */
  showDetails?: boolean
  /**
   * 显示编辑
   */
  showEdit?: boolean
  /**
   * 显示删除
   */
  showDelete?: boolean
  // 返回 true，代表按钮可用
  details?: (row: any) => boolean
  // 返回 true，代表按钮可用
  edit?: (row: any) => boolean
  // 返回 true，代表按钮可用
  delete?: (row: any) => boolean
  // 操作栏宽度，默认值150
  actionBarWidth?: string | number
}
// 分页器定义
export interface PagerDefine {
  total: number
  page?: number
  pageSize?: number
}

export type ShowType = 'disable' | 'hidden' | 'normal'

interface ITableUtil {
  /**
   * 生成表格绑定属性
   */
  readonly tableAttrs: { [name: string]: any }
}
/**
 * 统计Utils
 */
class SummaryUtils implements ITableUtil {
  #summary: boolean

  #tableAttrs: { [name: string]: any }

  /**
   *
   */
  constructor(
    cols: Array<ColDefine | SpecialColDefine>,
    summaryText: string,
    cellEmptyText = '-',
  ) {
    // 是否是统计表格
    const summary = cols.some((col) => (col as ColDefine)?.summary)
    const cache = new Map<string, boolean>()
    cols
      .filter((col: any) => col.name)
      .map((col) => col as ColDefine)
      .forEach((col) => {
        cache.set(col.name, !!col?.summary)
      })
    this.#summary = summary
    this.#tableAttrs = {
      showSummary: summary,
      summaryMethod: (params: {
        columns: Array<{ property: string }>
        data: Array<any>
      }) => {
        const result: Array<string> = []
        const { columns, data } = params

        for (let i = 0; i < columns.length; i++) {
          const column = columns[i]
          if (i === 0) {
            result.push(summaryText)
            // eslint-disable-next-line no-continue
            continue
          }
          // 不是统计属性列时
          if (!cache.get(column.property)) {
            result.push(cellEmptyText)
            // eslint-disable-next-line no-continue
            continue
          }

          const totalNumber = data.reduce((total, val) => {
            const num = Number(val[column.property])
            if (Number.isNaN(num)) return total
            return total + num
          }, 0)
          result.push(`${totalNumber}`)
        }

        return result
      },
    }
  }

  /**
   * 是否是统计表格
   */
  get summary() {
    return this.#summary
  }

  get tableAttrs() {
    return this.#tableAttrs
  }
}

export default defineComponent({
  emits: ['click-details', 'click-edit', 'click-delete'],
  props: {
    /**
     * 单元格数据为空时的文本
     */
    cellEmptyText: {
      type: String,
      default: '-',
    },
    // 显示分页器
    showPager: {
      type: Boolean,
      default: true,
    },
    /**
     * 显示操作栏
     */
    showActionBar: {
      type: Boolean,
      default: true,
    },
    /**
     * 显示表格底部的横线
     */
    showBorderBottom: {
      type: Boolean,
      default: true,
    },
    // 加载数据的方法
    load: {
      type: Function as PropType<LoadResolver>,
      required: true,
    },
    /**
     * 定义数据列
     */
    cols: {
      type: Array as PropType<Array<ColDefine | SpecialColDefine>>,
      required: true,
    },
    /**
     * 组件配置项
     */
    options: {
      type: Object as PropType<OptionsDefine>,
    },
    /**
     * 分页器
     */
    pager: {
      type: Object as PropType<PagerDefine>,
    },
    /**
     * 隐藏单页(分页器)
     */
    hideSinglePage: {
      type: Boolean,
      default: false,
    },
    /**
     * 允许拖拽
     */
    drag: {
      type: Boolean,
      default: false,
    },
    /**
     * 拖拽选项
     */
    dragOptions: {
      type: Object as PropType<
        Sortable.Options & {
          /**
           * el-table 表格拖拽容器选择器
           * @default '.el-table__body-wrapper .el-table__body tbody'
           */
          elWrapperClass?: string
        }
      >,
      default: () => undefined,
    },
    /**
     * 表格 Attrs
     */
    tableAttrs: {
      type: Object as PropType<Partial<typeof ElTable>>,
    },
    /**
     * 合计行第一列的文本
     */
    summaryText: {
      type: String,
      default: '合计',
    },
  },
  setup(props, { emit }) {
    const tableBridge = ref()
    const scrolling = ref(false)
    const elTableHeight = ref<string>()
    const elTableInnerWrapperHeight = ref<string>()

    const tableData = ref<Array<any>>([])
    const loading = ref(false)
    const mypager = ref<PagerDefine>({
      total: 0,
      page: 1,
      pageSize: 15,
    })

    const myOptions = ref<OptionsDefine>({
      disabled: false,
      actionBarWidth: 150,
      ...props.options,
    })
    const scrollBarRef = computed<HTMLDivElement>(
      () => tableBridge.value?.scrollBarRef.wrap$,
    )

    // 统计工具类
    const summaryUtils = new SummaryUtils(props.cols, props.summaryText)

    const myTableAttrs = ref<Partial<typeof ElTable>>({
      ...summaryUtils.tableAttrs,
      ...props.tableAttrs,
    })
    watch(
      () => props.pager?.total,
      (newval) => {
        mypager.value.total = newval || 0
      },
    )
    watch(
      () => props.pager?.page,
      (newval) => {
        mypager.value.page = newval
      },
    )
    watch(
      () => props.pager?.pageSize,
      (newval) => {
        mypager.value.pageSize = newval
      },
    )

    const reload = async () => {
      await nextTick()
      loading.value = true
      const { page = 1, pageSize = 10 } = mypager.value
      const res = props.load(page, pageSize)
      if (res instanceof Promise) {
        tableData.value = await res
      } else {
        tableData.value = res
      }

      loading.value = false
    }
    const onClickDetails = (row: any) => emit('click-details', row)
    const onClickEdit = (row: any) => emit('click-edit', row)
    const onClickDelete = (row: any) => emit('click-delete', row)
    // 计算按钮是否显示
    const calcShow = (row: any, cb?: (r: any) => boolean): ShowType => {
      const { disabled = false } = myOptions.value

      if (cb === undefined) return 'normal'
      const result = cb(row)
      if (result) {
        return 'normal'
      }
      if (disabled) {
        return 'disable'
      }
      return 'hidden'
    }

    // 空值格式化
    const emptyformatter = (
      cell: string | number,
      empty?: string,
      caseString?: boolean,
    ) => {
      let temp: string | number = ''
      if (typeof cell === 'string') {
        temp = (cell || empty) ?? props.cellEmptyText
      } else {
        temp = cell ?? empty ?? props.cellEmptyText
      }
      return caseString ? `${temp}` : temp
    }

    // 判断是否出现了滚动条
    const observer = new ResizeObserver(
      _.throttle((entries: ResizeObserverEntry[]) => {
        if (scrollBarRef.value && entries.length > 0) {
          const { contentRect } = entries[0]
          scrolling.value = scrollBarRef.value.scrollHeight > contentRect.height
          elTableInnerWrapperHeight.value = `${contentRect.height}px`
        } else {
          scrolling.value = false
        }
        if (tableBridge.value) {
          const el: HTMLDivElement = tableBridge.value.$el
          elTableHeight.value = `${el.clientHeight}px`
        }
      }, 150),
    )

    onMounted(() => {
      reload()
      scrollBarRef.value
        && scrollBarRef.value.parentElement
        && observer.observe(scrollBarRef.value.parentElement)
    })
    onUnmounted(() => {
      observer.disconnect()
    })
    return {
      elTableInnerWrapperHeight,
      elTableHeight,
      scrolling,

      tableData,
      tableBridge,
      reload,

      loading,
      mypager,
      myOptions,
      myTableAttrs,
      calcShow,
      emptyformatter,
      onClickDetails,
      onClickEdit,
      onClickDelete,
    }
  },
})

/**
 * 生成多级折叠表结构（辅助类）
 */
export class MultistageFolding<T = any> {
  // eslint-disable-next-line no-undef
  #colDefines: Array<ColDefine | SpecialColDefine>

  // eslint-disable-next-line no-undef
  #cols: Array<ColDefine | SpecialColDefine>

  #tableAttrs: any

  constructor(
    name: string,
    // eslint-disable-next-line no-undef
    zhNames: Array<string | Partial<ColDefine>>,
    // eslint-disable-next-line no-undef
    colDefines: Array<ColDefine | SpecialColDefine>,
    options: {
      load: (row: T, level: number) => Promise<Array<T>>
    },
  ) {
    this.#colDefines = colDefines

    // 初始化新树形折叠展开列
    this.#cols = zhNames.map((zhName, index) => {
      const prop = index === 0 ? name : `${name}_${index + 1}`
      if (typeof zhName === 'string') {
        return {
          name: prop,
          zhName,
        }
      }
      return {
        ...zhName,
        name: prop,
      }
    })

    this.#tableAttrs = {
      lazy: true,
      indent: 0,
      load: async (row: any, treeNode: any, resolve: any) => {
        if (row.__level === undefined) {
          row.__level = 0
        }

        const level = row.__level
        const result = await options.load(row, level)
        // console.log(`level ${level}`, level + 1 > zhNames.length)
        if (level + 1 > zhNames.length - 2) {
          result.forEach((obj: any) => {
            delete obj.hasChildren
          })
        }

        result.forEach((obj) => {
          Object.assign(obj, {
            __level: level + 1,
            __parent: row,
          })
        })
        // 计算跨行与跨列数据
        this.calcSpan(row, result, result.length, level)

        resolve(result)
      },
      rowClassName: ({ row, rowIndex }: { row: any; rowIndex: number }) => 'multistage-folding-repair',

      spanMethod: ({
        row,
        column,
        rowIndex,
        columnIndex,
      }: {
        row: any
        column: any
        rowIndex: number
        columnIndex: number
      }) => {
        const { __span } = row
        return __span && __span[columnIndex]
      },
    }
  }

  /**
   * 获取格式化后的数据列
   */
  get cols() {
    return [...this.#cols, ...this.#colDefines]
  }

  /**
   * 表格 Attrs
   */
  get tableAttrs() {
    return this.#tableAttrs
  }

  /**
   * 动态计算跨行间隔
   */
  calcSpan(row: any, sRows: Array<any>, count: number, level: number): void {
    if (level < 0) return

    sRows.forEach((sub) => {
      const span: { [name: number]: [number, number] } = {}
      // eslint-disable-next-line no-plusplus
      for (let key = 0; key <= level; key++) {
        span[key + 1] = [0, 0]
      }
      sub.__span = span
    })

    if (!row.__span) {
      const span = {
        0: [1 + count, 1],
      }
      row.__span = span
    } else if (row.__span[0]) {
      row.__span[0][0] += count
    } else {
      row.__span[0] = [1 + count, 1]
    }
    if (row.__parent) {
      this.calcSpan(row.__parent, [], count, level - 1)
    }
  }
}
