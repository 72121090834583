import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-144d94bd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-system-secondary-layout2 aw ah" }
const _hoisted_2 = { class: "nav" }
const _hoisted_3 = ["url", "onClick"]
const _hoisted_4 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menus, (item, i) => {
        return (_openBlock(), _createElementBlock("div", {
          url: item.path,
          class: _normalizeClass(["item", {
          activate: item.path === _ctx.path,
          first: i === 0,
        }]),
          onClick: ($event: any) => (_ctx.$router.push(item.path)),
          key: item.path
        }, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(item.title), 1)
        ], 10, _hoisted_3))
      }), 128))
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["view", {
        first: _ctx.index === 0,
      }])
    }, [
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      })
    ], 2)
  ]))
}