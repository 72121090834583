import _ from 'lodash'

export interface ICacheService<K, V> {
  clear(): void;
  delete(key: K): boolean;
  get(key: K): V | undefined;
  has(key: K): boolean;
  set(key: K, value: V): this;
}

/**
 * 内存缓存服务
 */
export class MemoryCacheService<K = string, V = any> implements ICacheService<K, V> {
  #cache = new Map<K, V>();

  clear(): void {
    this.#cache.clear()
  }

  delete(key: K): boolean {
    return this.#cache.delete(key)
  }

  get(key: K): V | undefined {
    return this.#cache.get(key)
  }

  has(key: K): boolean {
    return this.#cache.has(key)
  }

  set(key: K, value: V): this {
    this.#cache.set(key, value)
    return this
  }
}

export class StorageService implements Storage {
  /**
   * 内部存储对象
   */
  #storage: Storage;

  /**
   * 命名空间
   */
  #namespace: string

  #log: boolean;

  #cache:ICacheService<string, any>

  // eslint-disable-next-line no-restricted-globals
  [name: string]: any;

  /**
   * 初始化一个存储对象的服务
   * @param o
   */
  constructor(o: Storage | {
    /**
     * 数据存储对象，默认值 window.localStorage
     */
    storage?: Storage,
    /**
     * 命名空间
     */
    namespace?: string,
    /**
     * 写日志
     */
    log?: boolean
    /**
     * 数据缓存对象，默认值 MemoryCacheService
     */
    cache?: ICacheService<string, any>

  } = window.localStorage) {
    let temp
    if (o instanceof Storage) {
      temp = {
        storage: o,
      }
    } else {
      temp = o
    }
    const {
      storage, namespace, log, cache,
    } = {
      storage: window.localStorage,
      namespace: '',
      log: false,
      cache: new MemoryCacheService(),
      ...temp,
    }
    this.#storage = storage
    this.#namespace = namespace
    this.#log = log
    this.#cache = cache
  }

  get length(): number {
    return this.#storage.length
  }

  clear() {
    this.#storage.clear()
  }

  getItem(key: string): string | null {
    const result = this.#storage.getItem(this.#calcKey(key))
    if (this.#log) {
      console.log(`getItem ${this.#calcKey(key)} ${result}`)
    }
    return result
  }

  key(index: number): string | null {
    return this.#reverseKey(this.#storage.key(index))
  }

  removeItem(key: string): void {
    this.#storage.removeItem(this.#calcKey(key))
    if (this.#log) {
      console.log(`removeItem ${this.#calcKey(key)}`)
    }
  }

  setItem(key: string, value: string): void {
    this.#storage.setItem(this.#calcKey(key), value)
    if (this.#log) {
      console.log(`setItem ${this.#calcKey(key)} ${value}`)
    }
  }

  #calcKey(key: string): string {
    return this.#namespace ? `${this.#namespace}:${key}` : key
  }

  #reverseKey(key: string | null): string | null {
    if (!this.#namespace) return key
    if (!key) return key
    return key.replace(`${this.#namespace}:`, '')
  }

  get cache():ICacheService<string, any> {
    return this.#cache
  }
}
export default new StorageService()
