<template>
  <div class="app-system-empty">
    <slot name="icon">
      <img src="../assets/image/empty.png" alt="img" />
    </slot>
    <slot>
      <span class="text">暂无数据</span>
    </slot>
  </div>
</template>

<style lang="scss" scoped>
.app-system-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  .text {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.5);
    transform: translateY(-18px);
  }
}
</style>
