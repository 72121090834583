/*
 * @Author: wangminghua
 * @Date: 2022-04-03 07:04:08
 * @Last Modified by: wangminghua
 * @Last Modified time: 2022-09-15 15:42:51
 */

import _ from 'lodash'
import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'
import { MenuPermission } from '@/configs/define'

import { RouteLocationNormalizedLoaded } from 'vue-router'

import * as system from '@/api/app-system'

import { StorageService } from './storage'

const SYSTEMKEY = 'AVIGATORCITYPC'

const TOKENKEY = 'Token'
const REFRESHTOKENKEY = 'RefreshToken'
const EXPIRESKEY = 'Expires'
const PERMISSIONSKEY = 'Permissions'
const PERMISSIONSMENU = 'MenuPermissions'

const development = process.env.NODE_ENV === 'development'

/**
 * 存储服务
 */
export const storage = new StorageService({
  namespace: SYSTEMKEY,
  // log: development,
})

/**
 * 写开发日志，仅在开发环境下生效
 * @param  {...any} args
 */
export function log(...args: any[]) {
  if (development) { console.log.apply(null, args) }
}
/**
 * 延迟Promise对象
 * @param {*} wait 等待时间（毫秒）
 * @returns
 */
export function delay(wait: number) {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((resolve) => setTimeout(resolve, wait))
}

/**
 * 设置权限认证信息
 */
export function setAuthority(info: {
  /**
   * Token
   */
  token: string,
  /**
   * 刷新Token
   */
  refreshToken?: string,
  /**
   * 权限过期时间
   */
  expires?: number
}) {
  storage.setItem(TOKENKEY, info.token)
}

/**
 * 重置权限认证信息
 */
export function resetAuthority(info: {
  /**
   * Token
   */
  token: string,
  /**
   * 刷新Token
   */
  refreshToken?: string,
  /**
   * 权限过期时间
   */
  expires?: number
}) {
  storage.setItem(TOKENKEY, info.token)
}

/**
 * 清理权限
 */
export function clearAuthority() {
  storage.removeItem(TOKENKEY)
}

/**
 * 获取权限认证信息
 * @params ignorepPrmissions 忽略permissions
 * @returns 登录状态和具有的路由权限
 */
export async function authority(): Promise<{
  login: boolean,
  token?: string,
  refreshToken?: string,
  expires?: Date,
}> {
  const token = (storage.getItem(TOKENKEY) || '')

  return {
    login: !!token,
    token,
  }
}

/**
 * 获取权限认证信息
 * @params ignorepPrmissions 忽略permissions
 * @returns 登录状态和具有的路由权限
 */
export async function authorityPermissions(): Promise<{
  permissions: Array<string>,
  menuPermissions: Map<string, MenuPermission>,
}> {
  // const token = (storage.getItem(TOKENKEY) || '')

  // 不存在权限菜单列表时，初始化权限菜单列表到缓存中
  if (!storage.cache.has(PERMISSIONSKEY)) {
    const temp = await system.permissions()
    const {
      permissions: _permissions,
      menuPermissions: _menuPermissions,
    } = system.decoding(temp)
    storage.cache.set(PERMISSIONSKEY, _permissions)
    storage.cache.set(PERMISSIONSMENU, _menuPermissions)
  }

  const permissions = storage.cache.get(PERMISSIONSKEY) || []
  const menuPermissions = storage.cache.get(PERMISSIONSMENU) || new Map<string, MenuPermission>()

  return {
    permissions,
    menuPermissions,
  }
}

/**
 * 获取登录信息载荷
 * @returns
 */
export function payload(): {
  [property: string]: any
  } {
  const token = Cookies.get(TOKENKEY)
  return jwtDecode(token || '')
}

/**
 * 指定KEY鉴权
 * @param key 鉴权KEY
 */
export function auth(key: string): boolean {
  const permissions = (storage.getItem(PERMISSIONSKEY) || '').split(',')
  return _.includes(permissions, key)
}

/**
 * 路由是否有编辑权限
 * @param route 路由
 */
export async function canEditByRoute(route: RouteLocationNormalizedLoaded): Promise<boolean> {
  // 读取需要权限的路由列表
  const auths: Array<string> = _.chain(route.matched as any)
    .map((matche) => matche?.meta?.auth)
    .filter((authStr?: string) => authStr)
    .reverse()
    .value()
  // 不需要任何权限时，直接运行用户编辑
  if (auths.length === 0) return true

  const { menuPermissions } = await authorityPermissions()
  const item = menuPermissions.get(auths[0])

  // 取出最后一级路由，判断是否有编辑权限
  return !!item && item.permission === 'EDIT'
}

/**
 * 通过<a>标签下载文件（文件流）
 * @param url
 */
export function download(url: string) {
  const a = document.createElement('a')
  a.style.display = 'none'
  a.href = url
  a.download = ''
  document.body.appendChild(a)
  a.click()
  // 移除
  setTimeout(() => document.body.removeChild(a), 3000)
}

// 文件下载
export async function downloadFile(url: string, name: string) {
  const token = (storage.getItem(TOKENKEY) || '')
  const response = await fetch(url, {
    headers: {
      Authorization: `bearer ${token}`,
      cmsg: '5166f259fb07c67654c712336c7aa35dfb77c329b0e3d3887a78c55f92c28e7c45142dfff1a2cd6b',
    },
  })
  if (response.ok === false) {
    throw new Error(response.statusText)
  }

  // 内容转变成blob地址
  const blob = await response.blob()
  // 创建隐藏的可下载链接
  const objectUrl = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  // 地址
  a.href = objectUrl
  // 修改文件名
  a.download = name
  // 触发点击
  document.body.appendChild(a)
  a.click()
  // 移除
  setTimeout(() => document.body.removeChild(a), 1000)
}

/**
 * 解析路径为追加了API主机（process.env.VUE_APP_BASEURL）地址的URL路径
 * @param paths
 * @returns
 */
export function resolveApiHost(...paths: Array<string>): string {
  // 替换了结尾 / 的主机地址
  const baseURL = (process.env.VUE_APP_BASEURL || '').replace(/\/+$/, '')
  return [baseURL].concat(paths)
    .filter((t) => t)
    .map((t) => t.trim())
    .join('/')
    .replace(/\/+/g, '/')
    .replace(/(http(s|):\/)/i, '$1/')
}

export default () => 0
