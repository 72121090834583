import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_calendar = _resolveComponent("el-calendar")!

  return (_openBlock(), _createBlock(_component_el_calendar, {
    modelValue: _ctx.date,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.date) = $event)),
    ref: "calendar"
  }, {
    header: _withCtx(() => [
      _createVNode(_component_el_select, {
        modelValue: _ctx.selectedYear,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedYear) = $event)),
        placeholder: "请选择",
        onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateDate()))
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.yearList, (item) => {
            return (_openBlock(), _createBlock(_component_el_option, {
              value: item.value,
              label: item.label,
              key: item.value
            }, null, 8, ["value", "label"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_el_select, {
        class: "month",
        modelValue: _ctx.selectedMonth,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedMonth) = $event)),
        placeholder: "请选择",
        onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.updateDate()))
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.monthList, (item) => {
            return (_openBlock(), _createBlock(_component_el_option, {
              value: item.value,
              label: item.label,
              key: item.value
            }, null, 8, ["value", "label"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}