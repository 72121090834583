import { App } from 'vue'
import axios from './axios'
import elementPlus from './element-plus'
import theme from './theme'
// import mock from './mock'
import dayjs from './dayjs'
import mixin from './mixin'
import vueAmap from './vue-amap'

export default {
  install(app: App) {
    [
      axios,
      elementPlus,
      theme,
      dayjs,
      mixin,
      vueAmap,
    ].forEach((plugin) => app.use(plugin))
    // 开发环境使用mock
    // if (process.env.NODE_ENV === 'development') { app.use(mock) }
    // app.use(mock)
  },
}
