import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-af3a8a40"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  width: "1em",
  height: "1em",
  fill: "currentColor",
  "aria-hidden": "true"
}
const _hoisted_2 = ["xlink:href"]
const _hoisted_3 = {
  key: 1,
  width: "1em",
  height: "1em",
  fill: "currentColor",
  "aria-hidden": "true"
}
const _hoisted_4 = ["xlink:href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["el-icon", {'disabled':_ctx.disabled,'bg-box': !_ctx.readonly}]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
  }, [
    (_ctx.tooltip)
      ? (_openBlock(), _createBlock(_component_el_tooltip, {
          key: 0,
          content: _ctx.tooltip,
          placement: "top",
          "show-after": 1000
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createElementBlock("svg", _hoisted_1, [
              _createElementVNode("use", { "xlink:href": _ctx.href }, null, 8, _hoisted_2)
            ]))
          ]),
          _: 1
        }, 8, ["content"]))
      : (_openBlock(), _createElementBlock("svg", _hoisted_3, [
          _createElementVNode("use", { "xlink:href": _ctx.href }, null, 8, _hoisted_4)
        ]))
  ], 2))
}