import _ from 'lodash'
import axios from 'axios'
import { area } from '@turf/turf'

const prefix = '/avi-flight'

const workingCreateUrl = `${prefix}/workingTask/create`
const workingDeleteUrl = `${prefix}/workingTask/delete`
const workingUpdateUrl = `${prefix}/workingTask/update`
const workingDetailUrl = `${prefix}/workingTask/detail`
const workingisDemandIdUrl = `${prefix}/workingTask/isTaskByDemandId`
const workingDemandIdPageUrl = `${prefix}/workingTask/pageTaskByDemandId`
const workingTimePageUrl = `${prefix}/workingTask/pageTaskByTime`
const workingStatisticsUrl = `${prefix}/workingTask/statistics`
const historyTaskUrl = `${prefix}/workingTask/historyTask`
const workingAllocateUrl = `${prefix}/workingTask/allocateTasks`
const workingDroneDeviceUrl = `${prefix}/workingTask/droneDevice`
const workingPayloadDeviceUrl = `${prefix}/workingTask/payloadDevice`
const workingOtherDeviceUrl = `${prefix}/workingTask/ensureDevice`
const workingBatteryDeviceUrl = `${prefix}/workingTask/batteryDevice`

const workingExportUrl = `${prefix}/workingTask/exportworkingTask`
/** *************************************************任务相关接口******************************************** */

// 任务设备数据
export interface DeviceInfoData {
  batteryModel?:string, // 电池型号
  batteryCount?:number, // 电池数量
  droneModel?:string, // 无人机型号
  droneCount?:number, // 无人机数量
  otherDeviceModel?:string, // 其它
  payloadModel?:string, // 负载设备

  droneSnOfReal?:string, // 实际使用无人机编号
  payLoadSnOfReal?:string, // 实际使用负载编号
  otherSnOfReal?:string, // 实际使用其他设备编号
  batterySnOfReal?:Array<string>, // 实际使用电池编号

  id?:number, // id
  taskId?:number, // 任务id
}
// 出车信息
export interface CarDriveRecord {
  capacityOfComeBack?:number, // 回车油量/电量(%)
  capacityOfGetOut?:number, // 出车油量/电量(%)
  carNo?:string, // 车牌号
  dateOfComeBack?:string, // 回车时间
  dateOfGetOut?:string, // 出车时间
  driverId?:string, // 驾驶员(飞手userId）
  driverName?:string, // 驾驶员(飞手user名称
  id?:number, // 唯一编码
  imageOfInsideOfComeBack?:Array<string>, // 回车时照片url列表字符串
  imageOfInsideOfGetOut?:Array<string>, // 出车时照片url列表字符串
  mileageOfComeBack?:number, // 回车里程(km)
  mileageOfGetOut?:number, // 出车里程(km)
  remarkOfComeBack?:string, // 回车备注
  remarkOfGetOut?:string, // 出车备注
  taskId?:number, // 任务id
  thisMileage?:number, // 本次里程(km)
  travelTypeOfComeBack?:string, // 回车出行方式
  travelTypeOfGetOut?:string, // 出车出行方式
}
// 任务数据
export interface WorkingInfoData {
  date?:Array<string>, // 日期访问
  beginTime?: string, // 开始时间
  endTime?: string, // 结束时间
  address?:string, // 详细地址
  area?:string|Array<number>, // 作业地区
  areaType?:string, // 地区类型,可用值:I,II,III,IV
  carNo?:string, // 驾驶车辆
  coPilot?:Array<string>, // 副飞手
  demandId?:number, // 需求Id
  devices?:Array<DeviceInfoData>, // 设备列表
  interphone?:string, // 通信设备
  isCreateVideoAccessories?:string|number, // 是否推流 0否 1是
  mainlyId?:string, // 主飞手
  name?:string, // 任务名称
  projectId?:number, // 项目id
  requireOfTask?:string, // 任务要求
  workloadConfirmFlag?:string|number, // 验收单确认 0否 1是
  location?:Array<string|number>, // 位置经纬度坐标
  id?:number, // id
  carDriveRecord?: CarDriveRecord, // 出车信息

  deliverId?:string, // 交付经理id
  deliverName?:string, // 交付经理名称
  demandName?:string, // 需求名称
  demandType?:string, // 任务类型
  droneName?:string, // 任务设备
  flyHanderNames?:Array<string>, // 飞手名称列表
  mainName?:string, // 主飞手名称
  projectName?:string, // 项目名称
  status?:number, // 任务状态
}

// 更新任务
export function updateWorking(data:any):Promise<boolean> {
  const sendData = JSON.parse(JSON.stringify(data))
  if (Array.isArray(sendData.area)) {
    sendData.area = JSON.stringify(sendData.area)
  }
  return axios.put(workingUpdateUrl, sendData).then(() => true)
}
// 新增任务
export function addWorking(data:any):Promise<boolean> {
  const sendData = JSON.parse(JSON.stringify(data))
  if (Array.isArray(sendData.area)) {
    sendData.area = JSON.stringify(sendData.area)
  }
  return axios.post(workingCreateUrl, sendData).then(() => true)
}
// 删除任务
export function delWorking(id:string):Promise<boolean> {
  return axios.delete(workingDeleteUrl, {
    params: {
      id,
    },
  }).then(() => true)
}
// 任务详情
export function getWorking(id:string):Promise<any> {
  return axios.get(workingDetailUrl, {
    params: {
      id,
    },
  })
}
// 根据需求id查询任务列表
export function getWorkingDemandPage(demandId:number, params?: { beginTime?:string, endTime?:string, keyword?:string }, pageNumber = 1, pageSize = 10):Promise<any> {
  return axios.get(workingDemandIdPageUrl, {
    params: {
      ...params,
      demandId,
      pageNumber,
      pageSize,
    },
  })
}
// 根据时间查询任务列表
export function getWorkingTimePage(params?: { queryDate?:string }, pageNumber = 1, pageSize = 10):Promise<any> {
  return axios.get(workingTimePageUrl, {
    params: {
      ...params,
      pageNumber,
      pageSize,
    },
  })
}
// 飞手状态统计
export function getWorkingStatistics(queryDate:string):Promise<any> {
  return axios.get(workingStatisticsUrl, {
    params: {
      queryDate,
    },
  })
}
// 历史任务查询任务列表
export function historyTask(data?:{
  beginTime?:string,
  endTime?:string,
  keyword?:string,
  departId?:string,
  flyHanders?:Array<string>,
  status?:number,
  ids?:Array<number>,
 }, pageNumber = 1, pageSize = 10):Promise<any> {
  return axios.post(historyTaskUrl, {
    // beginTime: data?.beginTime,
    // endTime: data?.endTime,
    // keyword: data?.keyword,
    // departId: data?.departId,
    // flyHanders: data?.flyHanders?.join(','),
    // status: data?.status?.join(','),
    // ids: data?.ids?.join(','),
    ...data,
    pageNumber,
    pageSize,
  })
}
// 根据时间查询无人机列表
export function getWorkingDroneDevice(params?: { startDate?:string, endDate?:string }):Promise<any> {
  return axios.get(workingDroneDeviceUrl, {
    params: {
      ...params,
    },
  })
}
// 根据时间查询电池列表
export function getWorkingBatteryDevice(params?: {droneDeviceModel?:string, startDate?:string, endDate?:string }):Promise<any> {
  return axios.get(workingBatteryDeviceUrl, {
    params: {
      ...params,
    },
  })
}
// 根据时间查询负载列表
export function getWorkingPayloadDevice(params?: { droneDeviceModel?:string, startDate?:string, endDate?:string }):Promise<any> {
  return axios.get(workingPayloadDeviceUrl, {
    params: {
      ...params,
    },
  })
}
// 根据时间查询其他设备列表
export function getWorkingOtherDevice(params?: { startDate?:string, endDate?:string }):Promise<any> {
  return axios.get(workingOtherDeviceUrl, {
    params: {
      ...params,
    },
  })
}
// 下发任务
export function allocateWorking(ids:Array<number>):Promise<any> {
  return axios.get(workingAllocateUrl, {
    params: {
      ids: ids.join(','),
    },
  })
}
// 导出任务
export function exportWorking(beginTime:string, endTime:string):Promise<any> {
  return axios.get(workingExportUrl, {
    params: {
      beginTime,
      endTime,
    },
  })
}
