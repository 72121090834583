import _ from 'lodash'
import axios from 'axios'

import { IPage } from '@/configs/define'

export type UseType = 'month' | 'day' | 'week' | 'wday'

/**
 * 用户使用率模型
 */
export interface IUseRatio {
  /**
   * 唯一标识
   */
  id?: string | number
  /**
   * 文本
   */
  label: string
  /**
   * 任务率
   */
  task?: string
  /**
   * 内务率
   */
  affairs?: string
  /**
   * 备勤率
   */
  beiQin?: string
  /**
   * 调休率
   */
  daysOff?: string
  /**
   * 总计
   */
  total?: string
  /**
   * 排班人数
   */
  people?: number,
  /**
   * 是否有子项
   */
  hasChildren: boolean
}

/**
 * 获取利用率
 * @param query
 */
export async function getUseRatios(query?: {
  id: string | number,
  useType: UseType
}): Promise<Array<IUseRatio>> {
  const result: Array<IUseRatio> = []
  const url = '/avi-flight/flyhander/utilization'
  // 加载年统计数据
  if (!query) {
    const temp: Array<any> = await axios.get(url, {
      params: {
        type: 'YEAR',
      },
    }).then(({ data }) => data)
    temp.forEach((t) => {
      const {
        id, taskUtilization: task, total, waitUtilization: beiQin, restUtilization: daysOff, pilotCount: people, innerUtilization: affairs,
      } = t
      result.push({
        id,
        label: id,
        task,
        affairs,
        beiQin,
        daysOff,
        total,
        people,
        hasChildren: true,
      })
    })
  } else if (query.useType === 'month') {
    const { id: time } = query
    const temp: Array<any> = await axios.get(url, {
      params: {
        time,
        type: 'MONTH',
      },
    }).then(({ data }) => data)
    temp.forEach((t) => {
      const {
        id, taskUtilization: task, total, waitUtilization: beiQin, restUtilization: daysOff, pilotCount: people, innerUtilization: affairs,
      } = t
      result.push({
        id,
        label: id.replace(`${time}-`, ''),
        task,
        affairs,
        beiQin,
        daysOff,
        total,
        people,
        hasChildren: true,
      })
    })
  } else if (query.useType === 'day') {
    const { id: time } = query
    const temp: Array<any> = await axios.get(url, {
      params: {
        time,
        type: 'DAY',
      },
    }).then(({ data }) => data)
    temp.forEach((t) => {
      const {
        id, taskUtilization: task, total, waitUtilization: beiQin, restUtilization: daysOff, pilotCount: people, innerUtilization: affairs,
      } = t
      result.push({
        id,
        label: id.replace(`${time}-`, ''),
        task,
        affairs,
        beiQin,
        daysOff,
        total,
        people,
        hasChildren: true,
      })
    })
  } else if (query.useType === 'week') {
    const { id: time } = query
    const temp: Array<any> = await axios.get(url, {
      params: {
        time,
        type: 'WEEK',
      },
    }).then(({ data }) => data)
    temp.forEach((t) => {
      const {
        id, taskUtilization: task, total, waitUtilization: beiQin, restUtilization: daysOff, pilotCount: people, innerUtilization: affairs,
      } = t
      result.push({
        id,
        label: id.replace(`${time}-`, ''),
        task,
        affairs,
        beiQin,
        daysOff,
        total,
        people,
        hasChildren: true,
      })
    })
  } else if (query.useType === 'wday') {
    const { id: time } = query
    const temp: Array<any> = await axios.get(url, {
      params: {
        time,
        type: 'WEEK_DAY',
      },
    }).then(({ data }) => data)
    temp.forEach((t) => {
      const {
        id, taskUtilization: task, total, waitUtilization: beiQin, restUtilization: daysOff, pilotCount: people, innerUtilization: affairs,
      } = t
      result.push({
        id,
        label: id, // id.replace(`${time}-`, ''),
        task,
        affairs,
        beiQin,
        daysOff,
        total,
        people,
        hasChildren: true,
      })
    })
  }
  return result
}

export async function getTasks(time: Date): Promise<Array<any>> {
  return axios.get('/avi-flight/flyhander/dayTask', { params: { time } }).then(({ data }) => data)
}
