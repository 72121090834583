// 深色系
// 带--前缀的变量，代表新加变量
export default {
  'el-color-primary': '#00A4F0',
  'el-color-success': '#6DCC3D',
  'el-color-warning': '#f2ab3f',
  'el-color-danger': '#f54949',
  'el-color-error': '#f54949',
  'el-color-info': 'rgba(255, 255, 255, 0.1)',
  'el-color-primary-light-3': '#0094d9',

  'el-fill-color-light': 'rgba(255,255,255,0.05)',
  'el-text-color-placeholder': '#999999',
  'el-text-color-regular': '#CCCCCC',
  'el-border-color': 'rgba(255, 255, 255, 0.13)',
  'el-border-color-hover': '#00A4F0',
  'el-fill-color-blank': '#2C2D2E',
  'el-disabled-text-color': 'rgba(240, 242, 245, 0.6)',
  'el-disabled-bg-color': 'rgba(255, 255, 255, 0.1)',
  'el-disabled-border-color': 'rgba(255, 255, 255, 0)',
  '--el-input-disabled-opacity': '0.6',
  'el-box-shadow-light': '0px 0px 12px rgba(0, 0, 0, 0.15)',
  // 'el-border-radius-base': '18px',

  // 按钮配置
  '.el-button': {
    // 'el-border-radius-base': '12px',
    'el-button-bg-color': 'rgba(255, 255, 255, 0.1)',
    'el-button-border-color': 'transparent',
    'el-button-hover-bg-color': 'rgba(255, 255, 255, 0.1)',
    'el-button-hover-border-color': 'rgba(0, 164, 240, 1)',
    'el-button-text-color': 'rgba(255, 255, 255, 1)',
    'el-button-hover-text-color': 'rgba(255, 255, 255, 1)',
    'el-button-active-bg-color': 'rgba(255, 255, 255, 0.1)',
    'el-button-active-text-color': 'rgba(255, 255, 255, 1)',
    'el-button-active-border-color': 'rgba(0, 164, 240, 1)',
    'el-button-disabled-text-color': 'rgba(204, 204, 204, 0.6)',
    'el-button-disabled-border-color': 'transparent',
  },
  '.el-button--primary': {
    'el-button-bg-color': 'rgba(0, 164, 240, 1)',
    'el-button-border-color': 'transparent',
    'el-button-hover-bg-color': 'rgba(0, 164, 240, 0.8)',
    'el-button-active-bg-color': 'rgba(0, 164, 240, 1)',
    'el-button-disabled-bg-color': 'rgba(0, 164, 240, 0.6)',
    'el-button-disabled-text-color': 'rgba(255, 255, 255, 0.6)',
  },
  '.el-button--success': {
    'el-button-bg-color': 'rgba(59, 208, 144, 1)',
    'el-button-border-color': 'transparent',
    'el-button-hover-bg-color': 'rgba(59, 208, 144, 0.8)',
    'el-button-hover-border-color': 'transparent',
    'el-button-active-bg-color': 'rgba(59, 208, 144, 1)',
    'el-button-active-border-color': 'rgba(59, 208, 144, 1)',
    'el-button-disabled-bg-color': 'rgba(59, 208, 144, 0.6)',
    'el-button-disabled-text-color': 'rgba(255, 255, 255, 0.6)',
    'el-button-disabled-border-color': 'transparent',
  },
  '.el-button--warning': {
    '--el-button-bg-color': 'var(--el-color-warning)',
    '--el-button-border-color': 'var(--el-color-warning)',
    '--el-button-active-color': 'var(--el-color-warning-dark-2)',
    '--el-button-hover-bg-color': 'var(--el-color-warning-light-3)',
    '--el-button-hover-border-color': 'transparent',
    '--el-button-active-bg-color': 'var(--el-color-warning-dark-2)',
    '--el-button-active-border-color': 'var(--el-color-warning-dark-2)',
    '--el-button-disabled-text-color': 'var(--el-color-white)',
    '--el-button-disabled-bg-color': 'var(--el-color-warning-light-5)',
    '--el-button-disabled-border-color': 'transparent',
  },
  '.el-button--dashed': {
    'el-border-style': 'dashed',
    'el-border': 'var(--el-border-width) var(--el-border-style) var(--el-border-color)',
    'el-button-bg-color': 'transparent',
    'el-button-border-color': 'rgba(0, 164, 240, 1)',
    'el-button-text-color': 'rgba(0, 148, 217, 1)',
    'el-button-hover-bg-color': 'transparent',
    'el-button-hover-text-color': 'rgba(0, 148, 217, 1)',
    'el-button-active-bg-color': 'transparent',
    'el-button-active-text-color': 'rgba(0, 148, 217, 1)',
    'el-button-disabled-bg-color': 'transparent',
    'el-button-disabled-text-color': 'rgba(0, 164, 240, 0.6)',
  },
  '.el-button--text': {
    'el-button-bg-color': 'transparent',
    'el-button-border-color': 'transparent',
    'el-button-text-color': 'rgba(255, 255, 255, 1)',
    'el-button-hover-bg-color': 'transparent',
    'el-button-hover-text-color': 'rgba(0, 148, 217, 1)',
    'el-button-active-bg-color': 'transparent',
    'el-button-active-text-color': 'rgba(0, 148, 217, 1)',
    'el-button-disabled-bg-color': 'transparent',
    'el-button-disabled-text-color': 'rgba(204, 204, 204, 0.6)',
  },
  '.el-message': {
    'el-message-padding': '8px 15px 8px 20px',
    'el-border-width-base': '1px',
    'el-border-style-base': 'solid',
  },
  '.el-message--success': {
    'el-message-bg-color': 'rgba(18, 35, 9, 0.8)',
    'el-message-border-color': '#6DCC3D',
    'el-message-text-color': '#ffffff',
  },
  '.el-message-icon--success': {
    'el-message-text-color': '#6DCC3D',
  },
  '.el-message--warning': {
    'el-message-bg-color': 'rgba(42, 25, 0, 0.8)',
    'el-message-border-color': '#F2AB3F',
    'el-message-text-color': '#ffffff',
  },
  '.el-message-icon--warning': {
    'el-message-text-color': '#F2AB3F',
  },
  '.el-message--info': {
    'el-message-bg-color': 'rgba(0, 31, 46, 0.8)',
    'el-message-border-color': '#00A4F0',
    'el-message-text-color': '#ffffff',
  },
  '.el-message-icon--info': {
    'el-message-text-color': 'rgba(0, 164, 240, 1)',
  },
  '.el-message--error': {
    'el-message-bg-color': 'rgba(44, 10, 10, 0.8)',
    'el-message-border-color': '#F54949',
    'el-message-text-color': '#ffffff',
  },
  '.el-message-icon--error': {
    'el-message-text-color': '#F54949',
  },

  '.el-checkbox': {
    'el-border-color': 'transparent',
    // 'el-checkbox-font-size': '',
    // 'el-checkbox-font-weight': '',
    'el-checkbox-text-color': 'rgba(255, 255, 255, 1)',
    // 'el-checkbox-input-height': '',
    // 'el-checkbox-input-width': '',
    'el-checkbox-border-radius': '2px',
    'el-checkbox-bg-color': 'rgba(255, 255, 255, 0.1)',
    'el-checkbox-input-border': ' var(--el-border-width) var(--el-border-style) var(--el-border-color)',
    'el-checkbox-disabled-border-color': 'transparent',
    'el-checkbox-disabled-input-fill': 'rgba(245, 73, 73, 0.25)',
    'el-checkbox-disabled-icon-color': 'rgba(245, 73, 73, 0.5)',
    'el-checkbox-disabled-checked-input-fill': 'rgba(245, 73, 73, 0.25)',
    'el-checkbox-disabled-checked-input-border-color': 'transparent',
    'el-checkbox-disabled-checked-icon-color': 'rgba(245, 73, 73, 0.5)',
    'el-checkbox-checked-text-color': 'rgba(255, 255, 255, 1)',
    'el-checkbox-checked-input-border-color': 'transparent',
    'el-checkbox-checked-bg-color': 'rgba(0, 164, 240, 1)',
    'el-checkbox-checked-icon-color': 'rgba(255, 255, 255, 1)',
    'el-checkbox-input-border-color-hover': 'rgba(0, 164, 240, 1)',
  },

  '.el-radio': {
    // 'el-radio-font-size':'',
    'el-radio-text-color': 'rgba(255, 255, 255, 1)',
    // 'el-radio-font-weight':'',
    // 'el-radio-input-height':'',
    // 'el-radio-input-width':'',
    // 'el-radio-input-border-radius': '50%',
    'el-radio-input-bg-color': 'rgba(255, 255, 255, 0.1)',
    'el-radio-input-border': 'var(--el-border-width) var(--el-border-style) transparent',
    'el-radio-input-border-color': 'transparent',
    'el-radio-input-border-color-hover': 'rgba(0, 164, 240, 1)',
  },
  '.el-radio.is-checked': {
    'el-radio-input-bg-color': 'rgba(255, 255, 255, 0.1)',
    // 'el-radio-input-border': 'var(--el-border-width) var(--el-border-style) rgba(255, 255, 255, 0.1)',
    'el-radio-input-border-color': 'rgba(0, 164, 240, 1)',
    // 'el-radio-input-border-color-hover': 'rgba(0, 164, 240, 1)',
  },

  '.el-menu': {
    'el-menu-active-color': '#ffffff',
    'el-menu-text-color': 'rgba(255, 255, 255, 1)',
    'el-menu-hover-text-color': '#fff',
    'el-menu-bg-color': '#272829',
    'el-menu-hover-bg-color': 'rgba(255, 255, 255, 0.1)',
    'el-menu-border-color': '0',
    'el-menu-icon-transform-closed': 'rotateZ(-90deg)',
    'el-menu-icon-transform-open': 'rotateZ(0deg)',
  },
  '.el-table': {
    'el-table-bg-color': 'transparent',
    'el-table-tr-bg-color': 'rgba(39, 40, 41, 1)',
    '--el-table-tr-striped-bg-color': 'rgba(255,255,255,0.05)',
    'el-table-header-bg-color': 'rgba(255,255,255,0.05)',
    '--el-bg-color': '#272829',
    '--el-table-header-bg-color': 'rgba(255,255,255,0.05)',
    'el-table-header-text-color': '#999999',
    'el-table-border': '1px solid #424345;',
    '--el-table-border-bottom': '1px solid transparent;',
    'el-table-border-color': '#272829',
    'el-table-text-color': '#ffffff',
    'el-table-row-hover-bg-color': 'rgba(255, 255, 255, 0.1)',
  },
  '.el-empty': {
    'el-empty-fill-color-1': '#bbabab',
  },
  '.el-dialog': {
    'el-dialog-bg-color': 'var(--app-global-panel-color)',
    '--el-dialog-button-color': '#2c2d2e',
    '--el-dialog-button-color2': 'rgba(0, 164, 240, 1)',
    '--el-dialog-border': '1px solid var(--app-global-panel-color)',
    '--el-dialog-title-color': '#ffffff',
    '--el-dialog-title-border-color': 'rgba(255, 255, 255, 0.13)',
    'el-dialog-box-shadow': '0px 2px 20px 0px rgba(0, 0, 0, 0.8)',
  },
  '.el-link': {
    'el-link-text-color': '#ffffff',
    'el-link-hover-text-color': '#409eff',
    'el-link-disabled-text-color': '#a8abb2',
  },
  '.el-upload--picture-card': {
    'el-upload-picture-card-size': '70px',
  },

  '.el-tree': {
    '--el-tree-background': 'rgba(39, 40, 41, 0.5)',
    'el-tree-node-hover-bg-color': 'rgba(255, 255, 255, 0.1)',
    'el-tree-text-color': '#FFFFFF',
    'el-tree-expand-icon-color': '#FFFFFF',
  },

  '.el-select__popper': {
    '--el-select__popper-background': '#474849',
    '--el-select__popper-border': '1px solid transparent',
    '--el-select__popper-box-shadow': 'none',
  },

  '.el-input,.el-textarea,.el-date-range-picker,.el-date-editor,.el-picker-panel': {
    'el-input-text-color': '#ffffff',
    '--el-input-bg-color': 'rgba(255, 255, 255, 0.13)',
    'el-input-border-color': 'rgba(255,255,255,0)',
  },
  '.el-date-range-picker': {
    'el-datepicker-inner-border-color': 'rgba(255, 255, 255, 0.13)',
    'el-datepicker-inrange-bg-color': 'rgba(255, 255, 255, 0.13)',
    'el-datepicker-inrange-hover-bg-color': 'rgba(255,255,255,0.2)',
  },

  '.el-pagination': {
    'el-pagination-bg-color': '#474849',
    'el-pagination-button-bg-color': '#474849',
  },
  '.el-dropdown-menu': {
    '--el-dropdown-menu-background-color': '#474849',
  },
  '.el-message-box': {
    '--el-message-box-bg': '#393a3b',
    '--el-message-box__title-c': '#ffffff',
  },
  '.el-input__count': {
    '--el-input__count-c': '#999999',
  },
  '.el-input .el-input__clear:hover': {
    'el-input-clear-hover-color': '#00A4F0',
  },
  '.el-select .el-select__clear:hover': {
    '--el-select-clear-hover-color': '#00A4F0',
  },
  '.el-date-editor .el-range__close-icon:hover': {
    'el-text-color-secondary': '#00A4F0',
  },
  '.el-breadcrumb': {
    'el-text-color-primary': '#999',
    'el-text-color-regular': '#fff',
  },
}
