
import { IMenu } from '@/configs/define'
import {
  defineComponent, getCurrentInstance, PropType, ref, watch,
} from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  props: {
    /**
     * 菜单列表
     */
    menus: {
      type: Array as PropType<Array<IMenu>>,
      default: () => [],
    },
  },
  setup(props) {
    const route = useRoute()
    const index = ref(0)
    const path = ref('')
    watch(
      route,
      (r) => {
        path.value = r.path
        index.value = props.menus.findIndex((p) => p.path === r.path)
      },
      {
        immediate: true,
      },
    )
    return {
      index,
      path,
    }
  },
  mounted() {
    // console.log('ssassss', this.menus)
    // setTimeout(() => {
    //   this.$router.push('/container/assets/device/electric')
    // }, 5000)
  },
})
