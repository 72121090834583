import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7cee3be1"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href", "target"]
const _hoisted_2 = { class: "link" }
const _hoisted_3 = ["xlink:href"]
const _hoisted_4 = { class: "link" }
const _hoisted_5 = ["xlink:href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_app_system_menu_infinite_item = _resolveComponent("app-system-menu-infinite-item", true)!
  const _component_el_sub_menu = _resolveComponent("el-sub-menu")!

  return (_ctx.isRoot)
    ? (_openBlock(), _createBlock(_component_el_menu_item, {
        key: 0,
        class: "app-system-menu-infinite-item",
        index: _ctx.menu.path
      }, {
        default: _withCtx(() => [
          _createElementVNode("a", {
            href: _ctx.menu.path,
            target: _ctx.menu.target,
            onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
          }, [
            _createElementVNode("span", _hoisted_2, [
              _renderSlot(_ctx.$slots, "icon", {}, () => [
                (_ctx.menu.icon)
                  ? (_openBlock(), _createElementBlock("svg", {
                      key: 0,
                      class: "icon",
                      "aria-hidden": "true",
                      style: _normalizeStyle({
              '--icon-color': _ctx.menu.iconColor,
            })
                    }, [
                      _createElementVNode("use", {
                        "xlink:href": '#' + _ctx.menu.icon
                      }, null, 8, _hoisted_3)
                    ], 4))
                  : _createCommentVNode("", true)
              ], true),
              _createTextVNode(" " + _toDisplayString(_ctx.menu.title), 1)
            ])
          ], 8, _hoisted_1)
        ]),
        _: 3
      }, 8, ["index"]))
    : (_openBlock(), _createBlock(_component_el_sub_menu, {
        key: 1,
        class: "app-system-menu-infinite-item",
        index: _ctx.menu.path
      }, {
        title: _withCtx(() => [
          _createElementVNode("span", _hoisted_4, [
            _renderSlot(_ctx.$slots, "icon", {}, () => [
              (_ctx.menu.icon)
                ? (_openBlock(), _createElementBlock("svg", {
                    key: 0,
                    class: "icon",
                    "aria-hidden": "true",
                    style: _normalizeStyle({
              '--icon-color': _ctx.menu.iconColor,
            })
                  }, [
                    _createElementVNode("use", {
                      "xlink:href": '#' + _ctx.menu.icon
                    }, null, 8, _hoisted_5)
                  ], 4))
                : _createCommentVNode("", true)
            ], true),
            _createTextVNode(" " + _toDisplayString(_ctx.menu.title), 1)
          ])
        ]),
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu.children, (sub) => {
            return (_openBlock(), _createBlock(_component_app_system_menu_infinite_item, {
              menu: sub,
              key: sub.path
            }, null, 8, ["menu"]))
          }), 128))
        ]),
        _: 3
      }, 8, ["index"]))
}