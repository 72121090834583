
import _ from 'lodash'
import { defineComponent, ref, PropType } from 'vue'

type Size = 'large2' | 'large' | 'default2' | 'default' | 'small2' | 'small'

export default defineComponent({
  props: {
    showSubmit: {
      type: Boolean,
      default: true,
    },
    /**
     * 弹窗尺寸，设置width时，该尺寸不生效
     */
    size: {
      type: String as PropType<Size>,
      default: 'default',
    },
    title: {
      type: Boolean,
      required: true,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    /**
     * 全屏弹窗
     */
    fullscreen: {
      type: Boolean,
      default: false,
    },
    /**
     * 自定义弹窗宽度，设置值时 size 失效
     */
    width: {
      type: Number as PropType<number | undefined>,
    },
    /**
     * 是否显示底部按钮
     */
    showFooter: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['closed', 'opened', 'submit', 'cancel'],
  setup(props, context) {
    const show = ref(false)
    const onClosed = () => context.emit('closed')
    const onOpened = () => context.emit('opened')
    const onSubmit = () => context.emit('submit')
    const onCancel = () => context.emit('cancel')
    return {
      show,
      onClosed,
      onOpened,
      onSubmit: _.debounce(onSubmit, 300),
      onCancel,
    }
  },
  computed: {
    customClass() {
      return `app-system-dialog ${
        this.width || this.fullscreen ? '' : this.size
      }`
    },
  },
  methods: {
    open() {
      this.show = true
    },
    close() {
      this.show = false
    },
  },
})
