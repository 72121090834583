/*
 * @Author: wangminghua
 * @Date: 2022-03-24 15:44:50
 * @Last Modified by: wangminghua
 * @Last Modified time: 2022-08-01 15:28:17
 */

import _ from 'lodash'
import axios from 'axios'
import {
  setAuthority, resetAuthority, authority,
} from '@/utils'
import { treeForeach } from '@/utils/tree'
import { MenuPermission } from '@/configs/define'
import * as menu from './menu'
import * as role from './role'

export const loginUrl = '/oauth/oauths/login'
export const refreshUrl = '/sys/refresh/token'
export const permissionsUrl = '/avi-system/menus/menus'
const urlloginPrefix = '/oauth'
const sysPermissions = [
  'assets', 'cars', 'device', 'illegal', 'information', 'maintain', 'monitoring', 'out', 'battery', 'controller', 'payload', 'drone', 'safeguard', 'team', 'department', 'flyUser', 'role', 'user',
]

/**
 *
 * @param model
 * @returns
 * @example
 * {
 *    "account": "12345678911",
 *    "password": "qwertyu"
 * }
 */
export async function login(model:{
  account: string,
  password: string,
}): Promise<string> {
  // 用户登录
  const { access_token: token } = await axios.post(loginUrl, undefined, {
    // 密码进行md5 加密
    security: {
      password: 'md5',
    },
    clearAuthorization: true,
    params: {
      ...model,
    },
  // eslint-disable-next-line camelcase
  }).then<{access_token:string}>(({ data }) => data)
  setAuthority({
    token,
  })
  return token
}

/**
 * 刷新Token
 * @returns
 */
export async function refresh():Promise<string> {
  const { refreshToken } = await authority()
  const result:{
    token: string
    refreshToken: string
    expires: number
  } = await axios.get(refreshUrl, {
    clearAuthorization: true,
    params: {
      refreshToken,
    },
  }).then(({ data }) => ({ ...data, expires: data.expiredIn }))
  resetAuthority(result)
  return result.token
}

// 获取二维码
export function getQrcode() {
  return axios.get(`${urlloginPrefix}/userDetails/qrcode?grant_type=shine_login`)
}
// 查询登录状态
export function loginState(data:string) {
  return axios.get(`${urlloginPrefix}/userDetails/qrcode/info?noncestr=${data}`)
}
// 查询账号是否注册
export function isExist(data:string) {
  return axios.get(`${urlloginPrefix}/userDetails/exist?phone=${data}`)
}
/**
 * 获取短信验证码
 * @param {*} parameter
 * @returns
 */
export function getSmsCaptcha(data:any) {
  return axios.post(`/platform/publics/message/${data.type}/code`, data)
}

/**
 * 平台注册并绑定
 * @param {*} model
 * @returns
 */
export async function register(model:any) {
  const { access_token: token } = await axios.post(`${urlloginPrefix}/userDetails/register`, model, {
    // 密码进行md5 加密
    security: {
      password: 'md5',
    },
    clearAuthorization: true,
  // eslint-disable-next-line camelcase
  }).then<{access_token:string}>(({ data }) => data)
  // // 获取用户权限
  // const permissions = await axios.get(permissionsUrl, {
  //   clearAuthorization: true,
  //   headers: {
  //     token,
  //   },
  // }).then(({ data }) => {
  //   const fn = (_permissions:any) => {
  //     const temp = _permissions.map((p:any) => p.value)
  //     _permissions
  //       .filter((p:any) => p.children && p.children.length > 0)
  //       .forEach((p:any) => {
  //         Array.prototype.push.apply(temp, fn(p.children))
  //       })
  //     return temp
  //   }
  //   return fn(data)
  // })
  // 设置用户权限
  setAuthority({
    token,
  })
}
/**
 * 绑定
 * @param {*} data
 * @returns
 */
export async function bind(value:any) {
  const { data } = await axios.post(`${urlloginPrefix}/userDetails/bind`, value)

  setAuthority({
    token: data.access_token,
  })
}
/**
 * 忘记密码
 * @param {*} data
 * @returns
 */
export function forgetPassword(data:any) {
  return axios.put(`${urlloginPrefix}/userDetails/forget/rePassword`, data, {
    clearAuthorization: true,
    security: {
      password: 'md5',
    },
  })
}
