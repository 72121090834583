import axios from 'axios'

const prefix = '/avi-flight'

// ----------------项目信息--------------
// 项目列表(查询)
export function projectList(data: any) {
  return axios.get(`${prefix}/project/page`, { params: data })
}

// 数据同步
export function synchronizeProject() {
  return axios.post(`${prefix}/project/sync`)
}
// 项目信息状态修改
export function projectStatus(id:number) {
  return axios.get(`${prefix}/project/modifyStatus/${id}`)
}

// 获取项目详情
export function projectDetails(id: number) {
  return axios.get(`${prefix}/project`, { params: { id } })
}
// 新建-自动生成项目编号
export function projectSerialNo() {
  return axios.post(`${prefix}/project/queryTheLatestItemNumber`)
}

// 项目修改
export function projectUpdate(data: any) {
  return axios.put(`${prefix}/project`, data)
}

// 项目新建
export function newProject(data: any) {
  return axios.post(`${prefix}/project`, data)
}

// 项目删除
export function projectDelete(data: any) {
  return axios({
    url: `${prefix}/project`,
    method: 'delete',
    data,
  })
}
// 查询未完成的项目
export function queryUnfinishedProjects() {
  return axios.get(`${prefix}/project/queryUnfinishedProjects`)
}
// 根据项目id查询需求列表
export function queryRequirementsBasedOnProjectId(projectId: number) {
  return axios.get(`${prefix}/demand/queryRequirementsBasedOnProjectId/${projectId}`)
}
// ----------------项目范围--------------
// 导入项目范围
export function importProject(data: any) {
  return axios.post(`${prefix}/project/scope`, data)
}

// 获取项目范围列表
export function projectRange(projectId: number) {
  return axios.get(`${prefix}/project/scope`, { params: { projectId } })
}

// 获取已飞行范围项目列表 -- projectId
export function projectRangeByProjectId(projectId: number) {
  return axios.get(`${prefix}/workTaskFinishSection/selectFinishSectionListByProjectId`, { params: { projectId } })
}

// 项目删除
export function rangeDelete(data: any) {
  return axios({
    url: `${prefix}/project/scope`,
    method: 'delete',
    data,
  })
}

// 获取飞行详情
export function flyDetails(id: number) {
  return axios.get(`${prefix}/workingTask/detail`, { params: { id } })
}

// ----------------需求列表--------------
// 需求列表分页查询
export function demandList(data: any) {
  return axios.get(`${prefix}/demand/page`, { params: data })
}

// 需求列表状态修改
export function demandStatus(id:number) {
  return axios.get(`${prefix}/demand/stateModification/${id}`)
}

// 根据字典类型查询字典数据信息
export function demandTypeList(dictType: any) {
  return axios.get('/avi-system/dictDatas/type', { params: { dictType } })
}

// 删除需求
export function deleteDemand(demandId: number) {
  return axios.delete(`${prefix}/demand/${demandId}`)
}

// 需求修改
export function demandUpdate(data: any) {
  return axios.put(`${prefix}/demand`, data)
}

// 需求新建
export function newDemand(data: any) {
  return axios.post(`${prefix}/demand`, data)
}

// 当前公司人员列表
export function userList() {
  return axios.post('/avi-system/user/userList')
}

// 获取需求详情
export function demandDetails(id: number) {
  return axios.get(`${prefix}/demand/detail`, { params: { id } })
}

// ----------------需求范围--------------
// 获取已飞行范围项目列表 -- demandId
export function projectRangeByDemandId(demandId: number) {
  return axios.get(`${prefix}/workTaskFinishSection/selectFinishSectionListByProjectId`, { params: { demandId } })
}

// ----------------需求范围--------------
// 导入需求范围
// eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
export function importDemand(data: {
  demandId: number,
  scopes: Array<{
    area: number,
    geoJson: string,
    name: string
  }>
}) {
  return axios.post('/avi-flight/demandScope', data)
}

// 获取需求范围列表
export function demandRange(demandId: number) {
  return axios.get('/avi-flight/demandScope/selectDemandScopeListByDemandId', { params: { demandId } })
}

// 获取已飞行需求范围列表 -- demandId
export function demandRangeByDemandId(demandId: number) {
  return axios.get('/avi-flight/workTaskFinishSection/selectFinishSectionListByDemandId', { params: { demandId } })
}

// 需求范围删除
export function demandRangeDelete(data: {ids:Array<string|number>}) {
  return axios.delete('/avi-flight/demandScope/delete', {
    params: {
      id: data.ids[0],
    },
  })
}
