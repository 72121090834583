import axios, { AxiosResponse } from 'axios'

import { IFile, IPage } from '@/configs/define'
import { IListResult } from '@/configs/request-define'
/**
 * DRONE 无人机
 * BATTERY 电池
 * CONTROLLER 遥控器
 * PAYLOAD 载荷
 * SAFEGUARD 保障设备
 */
export type AssetType = 'DRONE' | 'BATTERY' | 'CONTROLLER' | 'PAYLOAD' | 'SAFEGUARD'

export interface IEnum {
  label: string,
  value: string,
  children?: Array<IEnum>
}

export async function getAssets(params: IPage & {
  keyword?: string
  assetType: AssetType
  deviceStatus?: string
}) {
  const { page: pageNumber, ...query } = params
  let { deviceStatus } = params
  if (!deviceStatus) deviceStatus = undefined
  return axios.get('/avi-flight/assets/page', {
    params: { pageNumber, ...query, deviceStatus },
  })
}

/**
 * 获取资产设备状态
 * @returns
 */
export async function getAssetStatus(): Promise<Array<{
  label: string,
  value: string
}>> {
  return axios.get('/avi-system/dictDatas/type', {
    params: { dictType: 'ass_device_status' },
  }).then((req) => req.data.map((dic: { dictValue: string, dictLabel: string }) => {
    const { dictValue: value, dictLabel: label } = dic
    return { value, label }
  }))
}

/**
 * 获取设备类型枚举（电池资产）
 * @returns
 */
export async function getDeviceTypeByBattery(): Promise<Array<IEnum>> {
  return axios.get('/avi-flight/batteryDict/getAll').then((req) => req.data.map((dic: { value: string, label: string, children: string }) => {
    const { value, label, children } = dic
    return { value, label, children: JSON.parse(children) }
  }))
}

/**
 * 获取设备类型枚举（无人机资产）
 * @returns
 */
export async function getDeviceTypeByDrone(): Promise<Array<IEnum>> {
  return axios.get('/avi-system/dictDatas/type', {
    params: { dictType: 'DRONE' },
  }).then((req) => req.data.map((dic: { dictValue: string, dictLabel: string }) => {
    const { dictValue: value, dictLabel: label } = dic
    return { value, label }
  }))
}

/**
 * 获取设备类型枚举（载荷资产）
 * @returns
 */
export async function getDeviceTypeByPayload(): Promise<Array<IEnum>> {
  return axios.get('/avi-system/dictDatas/type', {
    params: { dictType: 'PAYLOAD' },
  }).then((req) => req.data.map((dic: { dictValue: string, dictLabel: string }) => {
    const { dictValue: value, dictLabel: label } = dic
    return { value, label }
  }))
}

/**
 * 获取适用机型枚举
 * @param
 * @returns
 */
export async function getAssDroneName() {
  return axios.get('/avi-system/dictDatas/type', {
    params: { dictType: 'ass_drone_name' },
  }).then((req) => req.data.map((dic: { dictValue: string, dictLabel: string }) => {
    const { dictValue: value, dictLabel: label } = dic
    return { value, label }
  }))
}

/**
 * 获取供电模式枚举
 * @param
 * @returns
 */
export async function getPowerSupllyType() {
  return axios.get('/avi-system/dictDatas/type', {
    params: { dictType: 'power_suplly_type' },
  }).then((req) => req.data.map((dic: { dictValue: string, dictLabel: string }) => {
    const { dictValue: value, dictLabel: label } = dic
    return { value, label }
  }))
}

export interface InternalDateInterval {
  /**
   * 资产id，仅内部适用
   */
  id?: string| number
  /**
   * 保险日期间隔（仅限内部适用的字段）
   */
  daterange?: Array<any>
}

export interface TBase extends InternalDateInterval {
  /**
   * 附件列表
   */
  accessories: Array<IFile>
  /**
   * 资产类型
   */
  assetType: AssetType
  /**
   * 资产名称
   */
  deviceName: string
  /**
   * 资产编号
   */
  deviceSn: string
  /**
   * 途强设备ID
   */
  gpsDeviceId: string
  /**
   * 资产状态
   */
  deviceStatus: string
  /**
   * 资产备注
   */
  remark: string
  /**
   * 保险单号
   */
  insureNumber?: string
  /**
   * 保险开始日期
   */
  beginTimeOfInsure?: string
  /**
   * 保险结束日期
   */
  endTimeOfInsure?: string
  /**
   * 保险备注
   */
  remarkOfInsure?: string
}

/**
 * 电池资产类型
 */
export interface TBattery extends TBase {
  /**
   * 资产类型
   */
  assetType: 'BATTERY'
  /**
   * 电池设备
   */
  batteryDevice: {
    /**
     * 电池型号
     */
    batteryModel: string
    /**
     * 电池类型
     */
    batteryType: string,
    matchDevice: Array<string>,
  }
}

/**
 * 载荷类型
 */
export interface TPayload extends TBase {
  /**
   * 资产类型
   */
  assetType: 'PAYLOAD'
  /**
   * 载荷设备
   */
  payloadDevice: {
    /**
     * 功能类型
     */
    deviceType: Array<string>
    /**
     * 适用机型
     */
    matchDevice: Array<string>
  }
}

/**
 * 遥控器类型
 */
export interface TController extends TBase {
  /**
   * 资产类型
   */
  assetType: 'CONTROLLER'
  /**
   * 电池设备
   */
  remoteControlDevice: {
    /**
     * 电池型号
     */
    matchDrone: Array<string>
    /**
     * 供电模式
     */
    powerModel: '',
    /**
     * 飞控序列号
     */
    snOfFk: ''
  }
}

/**
 * 保障设备类型
 */
export interface TSafeguard extends TBase {
  /**
   * 资产类型
   */
  assetType: 'SAFEGUARD'
  /**
   * 电池设备
   */
  ensureDevice: {
    /**
     * 功能用途描述
     */
    useDesc: string
  }
}

/**
 * 电池资产类型
 */
export interface TDrone extends TBase {
  /**
   * 资产类型
   */
  assetType: 'DRONE'
  /**
   * 电池设备
   */
  droneDevice: {
    /**
     * 无人机类型
     */
    type: string
    /**
     * 无人机型号
     */
    model: string
    /**
     * 保养日期
     */
    maintenanceDate: string
  }
}

/**
 * 添加资产
 * @param model
 * @returns Promise
 */
export async function addAsset<T extends TBattery | TDrone | TPayload | TController | TSafeguard>(model: T): Promise<void> {
  // 附件处理

  const accessories = model.accessories.map((f) => ({ url: f.url, name: f.name, type: f.type }))
  if (model.daterange && model.daterange.length > 0) {
    Object.assign(model, {
      beginTimeOfInsure: model.daterange[0],
      endTimeOfInsure: model.daterange[1],
    })
  }
  return axios.post('/avi-flight/assets', { ...model, accessories })
}

/**
 * 更新资产信息
 * @param model
 */

export async function putAsset<T extends TBattery | TDrone | TPayload | TController | TSafeguard>(model: T): Promise<void> {
  const accessories = model.accessories.map((f) => ({
    id: f.id, url: f.url, name: f.name, type: f.type,
  }))
  if (model.daterange && model.daterange.length > 0) {
    Object.assign(model, {
      beginTimeOfInsure: model.daterange[0],
      endTimeOfInsure: model.daterange[1],
    })
  }

  return axios.put('/avi-flight/assets', { ...model, accessories })
}

/**
 * 获取资产详情
 * @param id 资产id
 * @returns TBattery | TDrone
 */
export async function getAsset<T extends TBattery | TDrone | TPayload | TController | TSafeguard>(id: string | number): Promise<T> {
  return axios.get('/avi-flight/assets/detail', {
    params: {
      id,
    },
  }).then((req) => {
    const { data } = req
    const accessories: Array<IFile> = data.accessories.map((p: any) => ({
      id: p.id,
      url: p.url,
      name: '',
      size: 0,
      type: '',
    }))

    if (data.beginTimeOfInsure && data.endTimeOfInsure) {
      data.daterange = [data.beginTimeOfInsure, data.endTimeOfInsure]
    } else {
      data.daterange = []
    }
    return { ...data, accessories }
  })
}

/**
 * 删除资产
 * @param id
 * @returns
 */
export function delAsset(id: string | number) {
  return axios.delete('/avi-flight/assets/batch/delete', {
    data: {
      ids: [id],
    },
  })
}

/**
 * 获取资产操作历史记录
 * @param id 资产id
 */
export function getHistoryRecord<T = any>(id: string | number, opts: IPage) {
  const { page: pageNumber, pageSize } = opts
  return axios.get<IListResult<T>>('/avi-flight/assets/findAssetModifyRecord', {
    params: {
      assetId: id,
      pageNumber,
      pageSize,
    },
  })
}
