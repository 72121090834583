import _ from 'lodash'
import axios from 'axios'

const prefix = '/avi-flight'

const internalUrl = `${prefix}/interiorTask/task`
const internalPageUrl = `${prefix}/interiorTask/pageQuery`
const internalDetailUrl = `${prefix}/interiorTask/detail`
const internalAllocateUrl = `${prefix}/interiorTask/allocateTasks`
const internalExportUrl = `${prefix}/interiorTask/exportInteriorTask`
/** *************************************************内务相关接口******************************************** */

export interface InternalInfoData {
  date?:Array<string>, // 日期访问
  beginTime?: string, // 开始时间
  endTime?: string, // 结束时间
  type?: string, // 内务类型
  flyHandlerId?: Array<string>|string, // 内务人员Id（飞手Id）
  flyHandlerName?: string, // 内务人员Name（飞手Name）
  id?: number, // 任务Id
  remark?: string, // 内务备注
  status?: number // 任务状态
}

// 更新内务
export function updateInternal(data:any):Promise<boolean> {
  return axios.put(internalUrl, data).then(() => true)
}
// 新增内务
export function addInternal(data:any):Promise<boolean> {
  return axios.post(internalUrl, data).then(() => true)
}
// 删除内务
export function delInternal(id:string):Promise<boolean> {
  return axios.delete(internalUrl, {
    params: {
      id,
    },
  }).then(() => true)
}
// 内务详情
export function getInternal(id:string):Promise<any> {
  return axios.get(internalDetailUrl, {
    params: {
      taskId: id,
    },
  })
}
// 内务分页
export function getInternalPage(params?: { queryDate?:string }, pageNumber = 1, pageSize = 10):Promise<any> {
  return axios.get(internalPageUrl, {
    params: {
      ...params,
      pageNumber,
      pageSize,
    },
  })
}
// 下发内务
export function allocateInternal(ids:Array<number>):Promise<boolean> {
  return axios.get(internalAllocateUrl, {
    params: {
      ids: ids.join(','),
    },
  }).then(() => true)
}
// 导出内务
export function exportInternal(beginTime:string, endTime:string):Promise<any> {
  return axios.get(internalExportUrl, {
    params: {
      beginTime,
      endTime,
    },
  })
}
