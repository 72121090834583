import _ from 'lodash'
import axios from 'axios'
import { IFile } from '@/configs/define'

const prefix = '/avi-flight'

const carInformation = `${prefix}/car`
const carlist = `${prefix}/car/dropList`
const carPage = `${prefix}/car/page`
const carIllegalRecord = `${prefix}/car/illegalRecord`
const carIllegalRecordPage = `${prefix}/car/illegalRecord/page`
const carMaintenance = `${prefix}/car/maintenance`
const carMaintenancePage = `${prefix}/car/maintenance/page`
const carMaintenanceTake = `${prefix}/car/maintenance/take`

export interface carQuery {
  carNo: string, // 车牌号码
  carCategory?:string, // 车辆归类（公车，私家车，租车）
  status?:string // 车辆状态
  date?: Array<string>, // 时间范围
  startTime?: string, // 开始时间
  endTime?: string, // 结束时间
}

// 车辆概要
export interface carBrief {
  id: number,
  carNo: string, // 车牌号码
  status: string, // 车辆状态
  carCategory: string, // 车辆归类（公车，私家车，租车）
  accessories?: Array<any>, // 附件
}

// 车辆保险
export interface carInsurance {
  carId?:number, // 车辆id
  company?:string, // 保险公司
  createTime?:string, // 创建时间
  current?:boolean, // 当前生效
  endTime?:string, // 保险结束创建时间
  id?:number, // id
  inspectTime?:string, // 年审日期
  orderNo?:string, // 保险单号
  remark?:string, // 备注
  startTime?:string, // 保险开始时间
  updateTime?:string, // 更新时间
  date?:Array<string>, // 保险事件
}
// 车辆详情
export interface carDetail {
  carNo: string, // 车牌号码
  carCategory:string, // 车辆归类（公车，私家车，租车）
  carType:string, // 车辆类型
  carProperty:string, // 车辆性质
  carManagerId?: number, // 车辆负责人
  status?:string // 车辆状态
  createTime?: string, // 创建时间
  deviceId?:string, // 设备id
  driveMode?:string, // 驾驶模式
  engineNo?:string, // 发动机编号
  frameNo?:string, // 车架号
  gpsManufacturer?:string, // gps设备厂商
  id?: number, // id
  phone?:string, // 联系电话
  remark?:string, // 备注
  updateTime?:string, // 更新时间
  rentalTime?:Array<string>, // 租车时间
  rentalStartTime?: string, // 租车开始时间
  rentalEndTime?: string, // 租车结束时间
  rentalPrice?:number|string, // 租车价格
  carInsurances?:Array<carInsurance>, // 历史保险
  carInsurance?: carInsurance, // 当前保险
  accessories?:Array<IFile>, // 附件
  carManager?:any, // 车辆负责人详情
}

export interface carIllegal {
  carNo:string, // 车牌号
  illegalTime:string, // 违章日期
  illegalDesc:string, // 违章类型
  money:number, // 罚金
  point:number, // 扣分
  driverName:string, // 驾驶员
  status:string, // 处理状态
  task:string, // 关联任务
  remark:string, // 备注
}

export interface carIllegalResponse {
  data:{
    records:Array<carIllegal>
    // 总数据量
    total: number
  }
}

export interface carPageeResponse {
  data:{
    records:Array<carDetail>
    // 总数据量
    total: number
  }
}

export interface carMaintenanceQuery {
  carId?: string, // 车辆id
  repairDate?:string, // 送修日期
  type?:string // 维保类型,可用值:REPAIR,MAINTENANCE
}

export interface carMaintenanceInfo {
  // carNo: number|string, // 车牌号码
  carId?: number, // 公车id
  category?: string, // 维保类别,可用值:SMALL,MIDDLE,LARGE
  exceptTakeDate?: string, // 预计取车日期
  handler?: number, // 经办人
  money?: number|string, // 维保费用
  nextMaintenanceDate?: string, // 下次保养日期
  nextMaintenanceMileage?: number|string, // 下次保养里程
  reason?: string, // 维保原因
  remark?: string, // 取车备注
  repairDate?: string, // 送修日期
  repairPlant?: string, // 修理厂
  takeDate?: string, // 取车日期
  type?: string, // 维保类型,可用值:REPAIR,MAINTENANCE
  handlerInfo?:any, // 经办人详情
  accessories?:Array<IFile>, // 附件
}

export interface carMaintenanceResponse {
  data:{
    records:Array<carMaintenanceInfo>
    // 总数据量
    total: number
  }
}

export function page(params?: carQuery, pageNumber = 1, pageSize = 10):Promise<carPageeResponse> {
  return axios.get(carPage, {
    params: {
      ...params,
      pageNumber,
      pageSize,
    },
  })
}

export function update(data:carDetail):Promise<boolean> {
  if (data.carInsurance && Object.values(data.carInsurance).length === 0) {
    data.carInsurance = undefined
  }
  if (data.rentalPrice) {
    data.rentalPrice = Number(data.rentalPrice) * 100
  }
  return axios.put(carInformation, data).then(() => true)
}

export function add(data:carDetail):Promise<boolean> {
  if (data.carInsurance && Object.values(data.carInsurance).length === 0) {
    data.carInsurance = undefined
  }
  if (data.rentalPrice) {
    data.rentalPrice = Number(data.rentalPrice) * 100
  }
  return axios.post(carInformation, data).then(() => true)
}

export function list():Promise<any> {
  return axios.get(carlist)
}

export function get(id:string):Promise<any> {
  return axios.get(carInformation, {
    params: {
      id,
    },
  })
}

export function del(id:string):Promise<boolean> {
  return axios.delete(carInformation, {
    data: {
      ids: [id],
    },
  }).then(() => true)
}

export function getIllegalPage(params?: carQuery, pageNumber = 1, pageSize = 10):Promise<carIllegalResponse> {
  return axios.get(carIllegalRecordPage, {
    params: {
      ...params,
      pageNumber,
      pageSize,
    },
  })
}

export function updateIllegalPage(data:any):Promise<boolean> {
  return axios.put(carIllegalRecord, data).then(() => true)
}

export function getCarMaintenance(id:string):Promise<any> {
  return axios.get(carMaintenance, {
    params: {
      id,
    },
  }).then((res) => {
    const { data } = res
    if (data.money) {
      data.money = Number(data.money) / 100
    }
    if (data.nextMaintenanceMileage) {
      data.nextMaintenanceMileage = Number(data.nextMaintenanceMileage) / 100
    }
    return { data }
  })
}

export function addCarMaintenance(data:carMaintenanceInfo):Promise<boolean> {
  if (data.money) {
    data.money = Number(data.money) * 100
  }
  if (data.nextMaintenanceMileage) {
    data.nextMaintenanceMileage = Number(data.nextMaintenanceMileage) * 100
  }
  return axios.post(carMaintenance, data).then(() => true)
}

export function updateCarMaintenance(data:carMaintenanceInfo):Promise<boolean> {
  if (data.money) {
    data.money = Number(data.money) * 100
  }
  if (data.nextMaintenanceMileage) {
    data.nextMaintenanceMileage = Number(data.nextMaintenanceMileage) * 100
  }
  return axios.put(carMaintenance, data).then(() => true)
}

export function pageCarMaintenance(params?: carMaintenanceQuery, pageNumber = 1, pageSize = 10):Promise<carMaintenanceResponse> {
  if (params && params.repairDate) {
    params.repairDate = new Date(params.repairDate).toISOString()
  }
  return axios.get(carMaintenancePage, {
    params: {
      ...params,
      pageNumber,
      pageSize,
    },
  })
}

export function takeCarMaintenance(data:carMaintenanceInfo):Promise<boolean> {
  if (data.money) {
    data.money = Number(data.money) * 100
  }
  if (data.nextMaintenanceMileage) {
    data.nextMaintenanceMileage = Number(data.nextMaintenanceMileage) * 100
  }
  return axios.put(carMaintenanceTake, data).then(() => true)
}
