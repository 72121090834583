
import {
  defineComponent, ref, computed, PropType, onMounted, watch,
} from 'vue'
import dayjs from 'dayjs'

export default defineComponent({
  emits: ['update:modelValue'],
  props: {
    defaultDate: {
      type: Date,
      default: new Date(),
    },
    yearRange: {
      type: Array as PropType<Array<number>>,
      default: () => [2010, 2100],
    },
    modelValue: {
      type: Date,
      default: new Date(),
    },
  },
  setup(props, { emit }) {
    const calendar = ref<any>()
    const selfModel = ref<Date>(new Date())
    const date = computed({
      get() {
        return props.modelValue ?? selfModel.value
      },
      set(val:Date) {
        emit('update:modelValue', val)
        selfModel.value = val
      },
    })

    const selectedYear = ref(date.value.getFullYear())
    const selectedMonth = ref(date.value.getMonth())
    watch(
      date,
      (val) => {
        selectedYear.value = val.getFullYear()
        selectedMonth.value = val.getMonth()
      },
    )

    const updateDate = () => {
      calendar.value.pickDay(dayjs(new Date(selectedYear.value, selectedMonth.value, 1)))
    }

    const yearList = ref<any>([])
    onMounted(() => {
      for (let i = props.yearRange[0]; i <= props.yearRange[1]; i += 1) {
        yearList.value.push({
          value: i,
          label: `${i}年`,
        })
      }
    })

    return {
      yearList,
      monthList: [
        { value: 0, label: '1月' },
        { value: 1, label: '2月' },
        { value: 2, label: '3月' },
        { value: 3, label: '4月' },
        { value: 4, label: '5月' },
        { value: 5, label: '6月' },
        { value: 6, label: '7月' },
        { value: 7, label: '8月' },
        { value: 8, label: '9月' },
        { value: 9, label: '10月' },
        { value: 10, label: '11月' },
        { value: 11, label: '12月' },
      ],
      date,
      selectedYear,
      selectedMonth,
      calendar,
      updateDate,
    }
  },
})
