import _ from 'lodash'
import axios from 'axios'

/**
 * 获取数据字典
 * @param
 * @returns
 */
export async function getDictDataType(dictType:string) {
  return axios.get('/avi-system/dictDatas/type', {
    params: { dictType },
  }).then((req) => req.data.map((dic: { dictValue: string, dictLabel: string }) => {
    const { dictValue: value, dictLabel: label } = dic
    return { value, label }
  }))
}
/**
 * 区域类型
 */
export async function getAreaTypeDefine() {
  return axios.get('/avi-system/dictDatas/type', {
    params: { dictType: 'prj_area_type' },
  }).then((req) => req.data.map((dic: { dictValue: string, dictLabel: string, cssClass:string }) => {
    const { dictValue: value, dictLabel: label, cssClass: tips } = dic
    return { value, label, tips }
  }))
}
