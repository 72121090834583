import {
  DirectiveBinding, DirectiveHook, ObjectDirective, VNode,
} from 'vue'

/**
 * 修饰符，代表支持进行哪种输入限制的判定
 */
type InputLimitType = 'number' | 'int' | 'float' | 'uint' | 'ufloat'

export class InputLimit implements ObjectDirective {
  created?: any = (el: HTMLElement, binding: DirectiveBinding & {
    modifiers: {
      [x in InputLimitType]?: boolean
    }
  }, vnode: VNode) => {
    const input = el instanceof HTMLInputElement ? el : el.querySelector('input')
    input?.addEventListener('input', (ev) => {
      // todo
      console.log('input', ev)
    })
  }
}

export default new InputLimit()
